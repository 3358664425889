import axios from "axios";

import {
  ALL_APPLICATION_FAIL,
  ALL_APPLICATION_REQUEST,
  ALL_APPLICATION_SUCCESS,
  APPLICATION_DETAILS_REQUEST,
  APPLICATION_DETAILS_SUCCESS,
  APPLICATION_DETAILS_FAIL,
  UPDATE_APPLICATION_STATUS_REQUEST,
  UPDATE_APPLICATION_STATUS_SUCCESS,
  UPDATE_APPLICATION_STATUS_FAIL,
  NEW_APPLICATION_FAIL,
  NEW_APPLICATION_REQUEST,
  NEW_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAIL,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/applicationConstants";



// Get ALL Cars in ADMIN
export const getAllApplications = (keyword = "") => async (dispatch, getState) => {

  const { user } = getState().user;
  try {
    dispatch({ type: ALL_APPLICATION_REQUEST });

    let link = `/api/v1/admin/applications?keyword=${keyword}`;

    if (user.role === "admin") {
      link = `/api/v1/admin/applications/me`;
    }
    const { data } = await axios.get(link);
    // ?keyword=${keyword}
    dispatch({
      type: ALL_APPLICATION_SUCCESS,
      payload: data.applications,
    });
  } catch (error) {
    dispatch({
      type: ALL_APPLICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};


//Add Application --- Admin
export const createApplication = (applicationData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_APPLICATION_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const { data } = await axios.post(`/api/v1/admin/application/new`, applicationData, config);

    dispatch({
      type: NEW_APPLICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_APPLICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};





//  Update Application
export const updateApplicationStatus = (id, application) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_APPLICATION_STATUS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      `/api/v1/admin/application/${id}`,
      application,
      config
    );

    dispatch({ type: UPDATE_APPLICATION_STATUS_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_APPLICATION_STATUS_FAIL,
      payload: error.response.data.message,
    });
  }
};



//Delete Application --- Admin
export const deleteApplication = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_APPLICATION_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/application/${id}`);

    dispatch({
      type: DELETE_APPLICATION_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_APPLICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};




//Application Details page
export const getApplicationDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: APPLICATION_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/admin/application/${id}`);

    dispatch({
      type: APPLICATION_DETAILS_SUCCESS,
      payload: data.application,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};





//Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

