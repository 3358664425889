import React from 'react'
import error from "./error.svg"
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <div classname="flex items-center justify-center w-full min-h-screen bg-gray-100">
    <div classname="flex flex-col text-gray-700 lg:flex-row lg:space-x-16 lg:space-x-reverse">
        <div classname="order-1 max-w-md px-2 text-sm md:text-base lg:px-0">
            <header classname="mb-6">
                <h2 classname="text-4xl font-bold leading-none text-gray-400 select-none lg:text-6xl">404.</h2>
                <h3 classname="text-xl font-light leading-normal lg:text-3xl md:text-3xl">Sorry, we couldn't find this page.</h3>
            </header>

            <p classname="max-w-sm mb-5 leading-5 md:leading-7">
                Don't worry, sometimes even we make mistakes.
                You can find plenty of other things on our dashboard.
            </p>

<Link to="/">
            <button
                classname="inline px-4 py-2 text-sm font-medium leading-5 text-white uppercase transition-colors duration-150 bg-indigo-500 border border-transparent rounded-lg shadow focus:outline-none focus:shadow-outline-blue active:bg-indigo-500 hover:bg-indigo-700">
                Back to Dashboard
            </button></Link>
        </div>

        <div classname="max-w-lg">
      <img  src={error} alt="Error"/>
        </div>
    </div>
</div>
  )
}

export default Error