import axios from "axios";

import {
  ALL_PICKUP_FAIL,
  ALL_PICKUP_REQUEST,
  ALL_PICKUP_SUCCESS,
  PICKUP_DETAILS_REQUEST,
  PICKUP_DETAILS_SUCCESS,
  PICKUP_DETAILS_FAIL,
  UPDATE_PICKUP_STATUS_REQUEST,
  UPDATE_PICKUP_STATUS_SUCCESS,
  UPDATE_PICKUP_STATUS_FAIL,
  NEW_PICKUP_FAIL,
  NEW_PICKUP_REQUEST,
  NEW_PICKUP_SUCCESS,
  DELETE_PICKUP_FAIL,
  DELETE_PICKUP_REQUEST,
  DELETE_PICKUP_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/pickupConstants";



// Get ALL Pickups in ADMIN
export const getAllPickups = () => async (dispatch) => {

  try {
    dispatch({ type: ALL_PICKUP_REQUEST });

    // let link = `/api/v1/admin/pickups?keyword=${keyword}`;
    let link = `/api/v1/admin/pickups`;

    // if (user.role === "admin") {
    //   link = `/api/v1/admin/applications/me`;
    // }
    const { data } = await axios.get(link);
    // ?keyword=${keyword}
    dispatch({
      type: ALL_PICKUP_SUCCESS,
      payload: data.pickups,
    });
  } catch (error) {
    dispatch({
      type: ALL_PICKUP_FAIL,
      payload: error.response.data.message,
    });
  }
};




// // get All Users
// export const getAllPickups = () => async (dispatch) => {
//   try {
//     dispatch({ type: ALL_PICKUP_REQUEST });
//     const { data } = await axios.get(`/api/v1/admin/pickups`);

//     dispatch({ type: ALL_PICKUP_SUCCESS, payload: data.pickups });
//   } catch (error) {
//     dispatch({ type: ALL_PICKUP_FAIL, payload: error.response.data.message });
//   }
// };

//Add Application --- Admin
export const createPickup = (pickupData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PICKUP_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const { data } = await axios.post(`/api/v1/pickup/new`, pickupData, config);

    dispatch({
      type: NEW_PICKUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PICKUP_FAIL,
      payload: error.response.data.message,
    });
  }
};





//  Update Application
export const updatePickupStatus = (id, pickup) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PICKUP_STATUS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      `/api/v1/admin/pickup/${id}`,
      pickup,
      config
    );

    dispatch({ type: UPDATE_PICKUP_STATUS_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PICKUP_STATUS_FAIL,
      payload: error.response.data.message,
    });
  }
};



//Delete Application --- Admin
export const deletePickup = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PICKUP_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/pickup/${id}`);

    dispatch({
      type: DELETE_PICKUP_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PICKUP_FAIL,
      payload: error.response.data.message,
    });
  }
};




//Pickup Details page
export const getPickupDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PICKUP_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/admin/pickup/${id}`);

    dispatch({
      type: PICKUP_DETAILS_SUCCESS,
      payload: data.pickup,
    });
  } catch (error) {
    dispatch({
      type: PICKUP_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};





//Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

