import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, forgotPassword } from "../actions/userAction";
import Loader from "./Loader";
import { useAlert } from "react-alert";
import { MdAlternateEmail } from "react-icons/md";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, message, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [email, setEmail] = useState("");

  const forgotPasswordSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("email", email);
    dispatch(forgotPassword(myForm));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (message) {
      alert.success(message);
    }
  }, [dispatch, error, alert, message]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="h-screen flex items-center justify-center">
          <form className="w-2/6" onSubmit={forgotPasswordSubmit}>
            <Player
              autoplay
              loop
              src="https://assets7.lottiefiles.com/packages/lf20_NU3Nmy.json"
              style={{ height: "200px", width: "200px" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>

            <h2 className="text-center text-2xl font-bold mb-4">
              Forgot Password?
            </h2>
            <div className="relative mb-4">
              <div className="relative">
                <span className="absolute inset-y-0 right-0 flex items-center p-2 ">
                  <button className="p-1 focus:outline-none">
                    <MdAlternateEmail className="text-indigo-500 text-base" />
                  </button>
                </span>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full py-4 pr-10 pl-4 text-sm font-normal focus:outline-none bg-white text-gray-700 block p-2 rounded border border-gray-200"
                />
              </div>
            </div>
            <button
              type="submit"
              className="p-2 bg-indigo-500 w-full text-white"
            >
              Send Recovery Email
            </button>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export default ForgotPassword;
