import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Loader from './Loader';
import Sidebar from './Sidebar';
import Signout from './Signout';
import { AiOutlineDelete } from "react-icons/ai";


import { useAlert } from "react-alert";

import { useSelector, useDispatch } from "react-redux";
import { clearErrors,getAllUsers,deleteUser } from "../actions/userAction";


import { DELETE_USER_RESET } from "../constants/userConstants";

const AllUsers = () => {


    const dispatch = useDispatch();
    const {loading, error,users} = useSelector((state) => state.allUsers);
    const {
      error: deleteError,
      isDeleted,
      message,
    } = useSelector((state) => state.profile);


    
  
    const deleteUserHandler = (userId) => {
      dispatch(deleteUser(userId));
      setIsModalOpen(false);
    };

    const alert = useAlert();


const [isModalOpen, setIsModalOpen] = useState(false);

useEffect(() => {
  if (error) {
    alert.error(error);
    dispatch(clearErrors());
  }

  if (deleteError) {
    alert.error(deleteError);
    dispatch(clearErrors());
  }

  if (isDeleted) {
    alert.success(message);
    // history.push("/admin/users");
    dispatch({ type: DELETE_USER_RESET });
  }

  dispatch(getAllUsers());
}, [dispatch, alert, error, deleteError, isDeleted, message]);




const downloadCSV = () => {
    const header = [
      "S. No.",
      "Agency",
      "Email",
      "Phone No.",
      "Case Manager",
      "Date Created",
      "Admin Role",
    ];
    const rows = users.map((u, index) => [
      `${index + 1}`,
      u.agency,
      u.email,
      u.phone,
      u.caseManagerName,
      u.createdAt.slice(0, 10),
      u.role === 'superAdmin' ? "MAHF" : "Agency",
    ]);
    const csv = [header, ...rows].map((r) => r.join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "All-Users.csv";
    link.click();
  };



  const customSort = (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    if (dateA < dateB) return 1;
    else if (dateA > dateB) return -1;
    return 0;
  };
  
  users.sort(customSort);


  return (
    <Fragment>
    <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48">

        </div>
<Sidebar/>
      <div className="m-3 w-full text-xl text-white font-semibold">
		<div className="grid grid-cols-2">
		<div className="">
        <h4 className="text-sm font-light">Users / View All Users</h4>
	<h2 className=" text-2xl font-semibold text-white leading-tight">All Users</h2>
        </div>
		<div className="flex justify-self-end pr-8 self-center gap-8">

    <button
                        onClick={downloadCSV}
                        className="bg-white bg-gradient-to-b hover:from-slate-600 hover:to-slate-700 text-indigo-600 font-normal text-sm hover:text-white flex self-center gap-1 py-2 px-6 rounded-md"
                      >
                        Export CSV
                      </button>
				<Signout/>
        </div>
		</div>
       

        {/* <!-- component --> */}
        <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">







    {loading ? (
      <Loader />
    ) : (
      <div className="">

    <div className="overflow-x-auto shadow-md max-h-[85vh] overflow-scroll scrollbar-hide rounded-2xl">
      
		<table className="min-w-full text-xs ">
			<colgroup>
				<col/>
				<col/>
				<col/>
				<col/>
				<col/>
				<col className="w-24"/>
			</colgroup>
			<thead className="bg-white border-b border-opacity-20 sticky top-0 border-gray-900">
				<tr className="text-left">
					<th className="p-4">Agency</th>
					<th className="p-4">Email</th>
					<th className="p-4">Phone</th>
					<th className="p-4">Case Manager</th>
					<th className="p-4">Date Added</th>
					<th className="p-4 text-right">Admin</th>
					<th className="p-4 text-right">Action</th>
				</tr>
			</thead>
			<tbody>
      {users.map((user) => {

        // Format createdAt date
        const createdAt = new Date(user.createdAt).toLocaleDateString('en-GB');
         return (
           <tr key={user._id} className="border-b border-opacity-20 border-gray-900 bg-white hover:bg-indigo-50 cursor-pointer">
          
					<td className="p-4">
          <Link to={`/user/${user._id}`}>
            <p>{user.agency}</p>
          </Link>
					</td>
					<td className="p-4">
          <Link to={`/user/${user._id}`}>
            <p>{user.email}</p>
          </Link>
					</td>
					<td className="p-4">
          <Link to={`/user/${user._id}`}>
            <p>{user.phone}</p>
          </Link>
					</td>
					<td className="p-4">
          <Link to={`/user/${user._id}`}>
            <p>{user.caseManagerName}</p>
          </Link>
					</td>
                    <td className="p-4">
          <Link to={`/user/${user._id}`}>
            <p>{createdAt}</p>
          </Link>
					</td>
					<td className="p-4 text-right">
          <Link to={`/user/${user._id}`}>
            <span className={`px-3 py-1 text-white font-semibold rounded-md ${
               user.role === 'superAdmin'
               ? 'bg-gradient-to-b from-green-400 to-green-500'
               :  user.role === 'admin'
               ? 'bg-gradient-to-b from-blue-300 to-blue-400'
               : ''
            } text-gray-900`}>
{user.role === 'superAdmin' ? (
  <span>MAHF</span>
) : user.role === 'admin' ? (
  <span>Agency</span>
) : null}
            </span>
          </Link>
					</td>
          <td className="p-4 text-center flex justify-end">
            <button 
             onClick={() => deleteUserHandler(user._id)}
             >

          <AiOutlineDelete className="text-xl hover:text-red-500"/>
            </button>
            {/* {isModalOpen &&  (
      <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-800 bg-opacity-30 "></div>
          <div className="bg-white p-6 rounded-lg z-10">
            <h2 className="text-xl font-semibold mb-4">Confirmation</h2>
            <p className="mb-4">Are you sure you want to delete this user?</p>
            <div className="flex justify-center">
              <button
                className="px-4 py-2 rounded-lg bg-red-500 text-white mr-2"
                onClick={() => deleteUserHandler(user._id)}
                
              >
                Confirm
              </button>
              <button
                className="px-4 py-2 rounded-lg bg-gray-300 text-gray-800"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}  */}
					</td>
				</tr>
        
         )
      })}
			</tbody>
		</table>
	</div>
  </div>
  )}
  </div>
      </div>
    </section>
  </Fragment>
  )
}

export default AllUsers