import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { BsClipboardCheck,BsTruck } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import icon from "./icon.svg";
import logo from "./logo.svg";
import { useLocation } from "react-router-dom";
import {
  FiChevronDown,
  FiChevronUp,
  FiUsers,
  FiUserPlus,
} from "react-icons/fi";
import { AiOutlineForm, AiOutlineFileProtect } from "react-icons/ai";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

const Sidebar = () => {
  const { user, } = useSelector((state) => state.user);

  const location = useLocation();
  const currentLocation = location.pathname;

  const [open, setOpen] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [userIsOpen, setUserIsOpen] = useState(false);

  const activeMenu =
    "group flex items-center text-sm gap-3.5 font-medium p-2 rounded-md bg-indigo-600 select-none";
  const menu =
    "group flex items-center text-sm gap-3.5 font-medium p-2 rounded-md hover:bg-slate-700 select-none";

  // This 'toggleDropdown' function toggles the value of the isOpen state variable between true and false
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown1 = () => {
    setUserIsOpen(!userIsOpen);
  };

  return (
    <div
      className={`bg-[#111c44] h-screen sticky top-0 ${
        open ? "w-72" : "w-16"
      } duration-500 text-gray-100 px-4`}
    >
      <div className="py-3 flex justify-end">
        <HiMenuAlt3
          size={26}
          className="cursor-pointer"
          onClick={() => setOpen(!open)}
        />
      </div>
      <div className="p-2 h-20 mb-2 flex justify-center items-center gap-2 text-white">
        <img src={logo} alt="logo" className="w-7" />
        <h1
          className={`whitespace-pr duration-500 text-sm ${
            !open && "translate-x-28 hidden text-xs"
          }`}
        >
          Assistance Management
        </h1>
      </div>
      <hr className="bg-gradient-to-r from-transparent via-gray-400 to-transparent h-0.5 border-none rounded-[100%]   mx-auto" />
      <div className="mt-4 flex flex-col gap-4 relative">
        <Link
          to="/dashboard"
          className={
            currentLocation === "/" || currentLocation === "/dashboard"
              ? activeMenu
              : menu
          }
        >
          <div className={`w-5 text-center`}>
            <MdOutlineDashboard className="text-[20px]" />
          </div>
          <h2
            style={{
              transitionDelay: `300ms`,
            }}
            className={`whitespace-pre duration-500 font-normal text-sm ${
              !open && "hidden"
            }`}
          >
            Dashboard
          </h2>
        </Link>
		{user.role === "superAdmin" && (
			<>
		<Link to="/all-pickup-requests">
            <div
              className={
                currentLocation === "/all-pickup-requests" 
                  ? activeMenu
                  : menu
              }
            //   onClick={toggleDropdown1}
            >
              <div className={`w-5 text-center`}>
                <BsTruck className="text-[20px]" />
              </div>
              <div className="inline-flex w-full items-center justify-between cursor-pointer">
                <h2
                  style={{
                    transitionDelay: `300ms`,
                  }}
                  className={`whitespace-pre duration-500 font-normal text-sm ${
                    !open && "hidden"
                  }`}
                >
                  Pickup Requests
                </h2>
              </div>
            </div>
			</Link>
          </>
        )}


        {user.role === "superAdmin" && (
          <>
            <div
              className={
                currentLocation === "/all-users" ||
                currentLocation === "/user/register"
                  ? activeMenu
                  : menu
              }
              onClick={toggleDropdown1}
            >
              <div className={`w-5 text-center`}>
                <AiOutlineUser className="text-[20px]" />
              </div>
              <div className="inline-flex w-full items-center justify-between cursor-pointer">
                <h2
                  style={{
                    transitionDelay: `300ms`,
                  }}
                  className={`whitespace-pre duration-500 font-normal text-sm ${
                    !open && "hidden"
                  }`}
                >
                  Users
                </h2>
                {userIsOpen ? (
                  <FiChevronUp className={`${!open && " hidden"}`} />
                ) : (
                  <FiChevronDown className={`${!open && " hidden"}`} />
                )}
              </div>
            </div>
            {userIsOpen && (
              <div className="origin-top-right  right-0 w-full rounded-md shadow-lg   z-10 ">
                <ul
                  className="flex-col flex gap-3.5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <Link to="/user/register">
                    <li
                      // onClick={() => handleCurrencyChange("USD")}
                      className={
                        currentLocation === "/user/register" ? activeMenu : menu
                      }
                    >
                      <div className={`w-5 text-center`}>
                        <FiUserPlus className="text-[20px]" />
                      </div>
                      <h3
                        className={`whitespace-pre duration-500 font-normal text-ls ${
                          !open && " hidden"
                        }`}
                      >
                        Add User
                      </h3>
                    </li>
                  </Link>
                  <Link to="/all-users">
                    <li
                      // onClick={() => handleCurrencyChange("INR")}
                      className={
                        currentLocation === "/all-users" ? activeMenu : menu
                      }
                    >
                      <div className={`w-5 text-center`}>
                        <FiUsers className="text-[20px]" />
                      </div>
                      <h3
                        className={`whitespace-pre duration-500 font-normal text-ls ${
                          !open && " hidden"
                        }`}
                      >
                        View All Users
                      </h3>
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </>
        )}
        <div
          className={
            currentLocation === "/new-application" ||
            currentLocation === "/all-application"
              ? activeMenu
              : menu
          }
          onClick={toggleDropdown}
        >
          <div className={`w-5 text-center`}>
            <BsClipboardCheck className="text-[20px]" />
          </div>
          <div className=" inline-flex w-full items-center justify-between cursor-pointer">
            <h2
              style={{
                transitionDelay: `300ms`,
              }}
              className={`whitespace-pre duration-500 font-normal text-sm ${
                !open && "hidden"
              }`}
            >
              Applications
            </h2>

            {isOpen ? (
              <FiChevronUp className={`${!open && " hidden"}`} />
            ) : (
              <FiChevronDown className={`${!open && " hidden"}`} />
            )}
          </div>
        </div>
        {isOpen && (
          <div className="origin-top-right  right-0 w-full rounded-md shadow-lg   z-10 ">
            <ul
              className="flex-col flex gap-3.5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <Link to="/new-application">
                <li
                  className={
                    currentLocation === "/new-application" ? activeMenu : menu
                  }
                >
                  <div className={`w-5 text-center`}>
                    <AiOutlineForm className="text-[20px]" />
                  </div>
                  <h3
                    className={`whitespace-pre duration-500 font-normal text-ls ${
                      !open && " hidden"
                    }`}
                  >
                    Create Application
                  </h3>
                </li>
              </Link>
              <Link to="/all-application">
                <li
                  className={
                    currentLocation === "/all-application" ? activeMenu : menu
                  }
                >
                  <div className={`w-5 text-center`}>
                    <AiOutlineFileProtect className="text-[20px]" />
                  </div>
                  <h3
                    className={`whitespace-pre duration-500 font-normal text-ls ${
                      !open && " hidden"
                    }`}
                  >
                    View All Applications
                  </h3>
                </li>
              </Link>
            </ul>
          </div>
        )}

        <div className="mt-4 flex flex-col gap-4 relative">
          <Link
            to="/account"
            className={currentLocation === "/account" ? activeMenu : menu}
          >
            <div>
              <CgProfile className="text-xl" />
            </div>
            <h2
              className={`whitespace-pre duration-500 font-normal text-sm ${
                !open && " overflow-hidden"
              }`}
            >
              Profile
            </h2>
          </Link>
        </div>
        <div className=" flex flex-col items-center mt-8">
          <img src={icon} alt="Help?" className="w-28" />
          <h2
            className={`whitespace-pre duration-500 text-ls ${
              !open && " hidden"
            }`}
          >
            Need help?
          </h2>
          <span
            className={` whitespace-pre duration-500 text-xs ${
              !open && " hidden"
            }`}
          >
            Please check our docs
          </span>
          <button
            className={`mt-4 whitespace-pre duration-500 rounded-lg p-2 w-full text-xs font-medium bg-gradient-to-b from-slate-600 to-slate-700 ${
              !open && " hidden"
            }`}
          >
            Documentation
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
