import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { NEW_PICKUP_RESET } from "../constants/pickupConstants";
import Loader from "./Loader";
import { Player } from "@lottiefiles/react-lottie-player";
import { BsCurrencyDollar } from "react-icons/bs";
import {clearErrors, createPickup } from '../actions/pickupAction';
import { useNavigate } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const NewPickup = () => {



    const cities = [
        { name: 'Andover', price: 105 },
        { name: 'Ansonia', price: 100 },
  { name: 'Ashford', price: '150' },
  { name: 'Avon', price: '100' },
  { name: 'Barkhamsted', price: '110' },
  { name: 'Beacon Falls', price: '100' },
  { name: 'Berlin', price: '100' },
  { name: 'Bethany', price: '100' },
  { name: 'Bethel', price: '110' },
  { name: 'Bethlehem', price: '100' },
  { name: 'Bloomfield', price: '110' },
  { name: 'Bolton', price: '115' },
  { name: 'Bozrah', price: '155' },
  { name: 'Branford', price: '115' },
  { name: 'Bridgeport', price: '115' },
  { name: 'Bridgewater', price: '100' },
  { name: 'Bristol', price: '100' },
  { name: 'Brookfield', price: '100' },
  { name: 'Brooklyn', price: '180' },
  { name: 'Burlington', price: '100' },
  { name: 'Canaan', price: '120' },
  { name: 'Canterbury', price: '180' },
  { name: 'Canton', price: '115' },
  { name: 'Chaplin', price: '155' },
  { name: 'Cheshire', price: '100' },
  { name: 'Chester', price: '115' },
  { name: 'Clinton', price: '115' },
  { name: 'Colchester', price: '140' },
  { name: 'Colebrook', price: '140' },
  { name: 'Columbia', price: '140' },
  { name: 'Cornwall', price: '120' },
  { name: 'Coventry', price: '140' },
  { name: 'Cromwell', price: '100' },
  { name: 'Danbury', price: '115' },
  { name: 'Darien', price: '165' },
  { name: 'Deep River', price: '115' },
  { name: 'Derby', price: '100' },
  { name: 'Durham', price: '115' },
  { name: 'East Granby', price: '115' },
  { name: 'East Haddam', price: '115' },
  { name: 'East Hampton', price: '115' },
  { name: 'East Hartford', price: '110' },
  { name: 'East Haven', price: '110' },
  { name: 'East Lyme', price: '155' },
  { name: 'East Windsor', price: '115' },
  { name: 'Eastford', price: '180' },
  { name: 'Easton', price: '105' },
  { name: 'Ellington', price: '150' },
  { name: 'Enfield', price: '105' },
  { name: 'Essex', price: '140' },
  { name: 'Fairfield', price: '130' },
  { name: 'Farmington', price: '100' },
  { name: 'Franklin', price: '155' },
  { name: 'Glastonbury', price: '105' },
  { name: 'Goshen', price: '105' },
  { name: 'Granby', price: '115' },
  { name: 'Greenwich', price: '185' },
  { name: 'Griswold', price: '195' },
  { name: 'Groton', price: '175' },
  { name: 'Guilford', price: '105' },
  { name: 'Haddam', price: '115' },
  { name: 'Hamden', price: '100' },
  { name: 'Hampton', price: '180' },
  { name: 'Hartford', price: '100' },
  { name: 'Hartland', price: '140' },
  { name: 'Harwinton', price: '100' },
  { name: 'Hebron', price: '115' },
  { name: 'Kent', price: '120' },
  { name: 'Killingly', price: '195' },
  { name: 'Killingworth', price: '115' },
  { name: 'Lebanon', price: '140' },
  { name: 'Ledyard', price: '155' },
  { name: 'Lisbon', price: '155' },
  { name: 'Litchfield', price: '120' },
  { name: 'Lyme', price: '120' },
  { name: 'Madison', price: '115' },
  { name: 'Manchester', price: '100' },
  { name: 'Mansfield', price: '140' },
  { name: 'Marlborough', price: '115' },
  { name: 'Meriden', price: '100' },
  { name: 'Middlebury', price: '100' },
  { name: 'Middlefield', price: '100' },
  { name: 'Middletown', price: '115' },
  { name: 'Milford', price: '115' },
  { name: 'Monroe', price: '100' },
  { name: 'Montville', price: '155' },
  { name: 'Morris', price: '100' },
  { name: 'Naugatuck', price: '100' },
  { name: 'New Britain', price: '100' },
  { name: 'New Canaan', price: '130' },
  { name: 'New Fairfield', price: '115' },
  { name: 'New Hartford', price: '115' },
  { name: 'New Haven', price: '100' },
  { name: 'New London', price: '150' },
  { name: 'New Milford', price: '115' },
  { name: 'Newington', price: '100' },
  { name: 'Newtown/Sandy Hook', price: '100' },
  { name: 'Norfolk', price: '140' },
  { name: 'North Branford', price: '120' },
  { name: 'North Canaan', price: '165' },
  { name: 'North Haven', price: '115' },
  { name: 'North Stonington', price: '165' },
  { name: 'Norwalk', price: '140' },
  { name: 'Norwich', price: '180' },
  { name: 'Old Lyme', price: '155' },
  { name: 'Old Saybrook', price: '140' },
  { name: 'Orange', price: '100' },
  { name: 'Oxford', price: '100' },
  { name: 'Plainfield', price: '195' },
  { name: 'Plainville', price: '100' },
  { name: 'Plymouth', price: '100' },
  { name: 'Pomfret', price: '180' },
  { name: 'Portland', price: '115' },
  { name: 'Preston', price: '155' },
  { name: 'Prospect', price: '100' },
  { name: 'Putnam', price: '195' },
  { name: 'Redding', price: '115' },
  { name: 'Ridgefield', price: '115' },
  { name: 'Rocky Hill', price: '100' },
  { name: 'Roxbury', price: '100' },
  { name: 'Salem', price: '140' },
  { name: 'Salisbury', price: '165' },
  { name: 'Scotland', price: '180' },
  { name: 'Seymour', price: '100' },
  { name: 'Sharon', price: '120' },
  { name: 'Shelton', price: '100' },
  { name: 'Sherman', price: '115' },
  { name: 'Simsbury', price: '115' },
  { name: 'Somers', price: '140' },
  { name: 'South Windsor', price: '115' },
  { name: 'Southbury', price: '100' },
  { name: 'Southington', price: '100' },
  { name: 'Sprague', price: '180' },
  { name: 'Stafford', price: '165' },
  { name: 'Stamford', price: '165' },
  { name: 'Sterling', price: '195' },
  { name: 'Stonington', price: '195' },
  { name: 'Stratford', price: '115' },
  { name: 'Suffield', price: '140' },
  { name: 'Thomaston', price: '100' },
  { name: 'Thompson', price: '195' },
  { name: 'Tolland', price: '140' },
  { name: 'Torrington', price: '100' },
  { name: 'Trumbull', price: '115' },
  { name: 'Union', price: '165' },
  { name: 'Vernon', price: '115' },
  { name: 'Voluntown', price: '195' },
  { name: 'Wallingford', price: '100' },
  { name: 'Warren', price: '115' },
  { name: 'Washington', price: '115' },
  { name: 'Waterbury', price: '100' },
  { name: 'Waterford', price: '155' },
  { name: 'Watertown', price: '100' },
  { name: 'West Hartford', price: '115' },
  { name: 'Westbrook', price: '140' },
  { name: 'Weston', price: '105' },
  { name: 'Westport', price: '130' },
  { name: 'Wethersfield', price: '100' },
  { name: 'Willington', price: '165' },
  { name: 'Wilton', price: '120' },
  { name: 'Winchester', price: '115' },
  { name: 'Windham', price: '180' },
  { name: 'Windsor', price: '115' },
  { name: 'Windsor Locks', price: '115' },
  { name: 'Wolcott', price: '100' },
  { name: 'Woodbridge', price: '100' },
  { name: 'Woodbury', price: '100' },
  { name: 'Woodstock', price: '180' }
        
        // ... Add the rest of the cities and prices here
      ];

 console.log(cities)

      const handleCityChange = (event) => {
        const city = event.target.value;
        setCity(city);
    
        // Find the selected city price
        const cityPrice = cities.find((cityPrice) => cityPrice.name === city);
        setSelectedCityPrice(cityPrice ? cityPrice.price : '');
        setPickupCharges(selectedCityPrice);
      };


      

    const { loading, error, success } = useSelector(
        (state) => state.newPickup
      );

      const [showAlert, setShowAlert] = useState(false);
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const alert = useAlert();
    
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [phone, setPhone] = useState("");
      const [address, setAddress] = useState("");
      const [state, setState] = useState("");
      const [city, setCity] = useState("");
      const [zipcode, setZipcode] = useState("");
      const [items, setItems] = useState([]);
      const [comments, setComments] = useState("");
      const [pickupCharges, setPickupCharges] = useState();



  const [selectedCityPrice, setSelectedCityPrice] = useState();


   
console.log(items)

      const options =[
        {id:1, label: 'Box & Bags'},
        {id:2, label: 'Kitchen Appliances'},
        {id:3, label: 'Recreation & Sporting Goods'},
        {id:4, label: 'Dining Room'},
        {id:5, label: 'Outdoors'},
        {id:6, label: 'Cleaning Supplies'},
        {id:7, label: 'Living Room'},
        {id:8, label: 'Office'},
        {id:9, label: 'Hygiene Products'},
        {id:10, label: 'Bedroom'},
        {id:11, label: 'Bathroom'},
        {id:12, label: 'Electronics'},
        {id:13, label: 'Others'},
      ]


      const handleCheckboxChange = (optionId) => {
        const isChecked = items.includes(optionId);

        if(isChecked){
            setItems(items.filter((value) => value !== optionId));
        } else {
            setItems([...items, optionId]);
        }
      }

      useEffect(() => {
        if (error) {
          alert.error(error);
          dispatch(clearErrors());
        }
        if (success) {
          setShowAlert(true);
          dispatch({ type: NEW_PICKUP_RESET });
        }
        
      }, [dispatch, alert, error, success]);



      const createPickupSubmitHandler = (e) => {
        e.preventDefault();
    
        const myForm = new FormData();
    
        myForm.set("name", name);
        myForm.set("email", email);
        myForm.set("phone", phone);
        myForm.set("address", address);
        myForm.set("state", state);
        myForm.set("city", city);
        myForm.set("zipcode", zipcode);
        myForm.set("items", items);
        myForm.set("comments", comments);
        myForm.set("pickupCharges", pickupCharges);


        dispatch(createPickup(myForm));
    };


console.log(pickupCharges)
   

  return (
    <div className="flex justify-center items-center px-2 md:px-20 py-10">
            {loading ? (
              <Loader />
            ) : (
              <div className=" rounded-2xl shadow bg-white w-full    text-gray-800">
                <form
                  id="pdf-content"
                  className=" w-full text-sm  p-8 mx-auto space-y-6 ng-untouched ng-pristine ng-valid"
                  onSubmit={createPickupSubmitHandler}
                >
                  <div className="grid grid-cols-3 gap-8">
        
                  

                    <div className="col-span-full text-indigo-600 text-3xl font-bold leading-tight">
                    Request Pickup 
                    </div>
                    <div className="col-span-full md:col-span-1">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Full Name :
                      </label>
                      <input
                        id="carName"
                        type="text"
                        className="mt-1 w-full h-10 px-2 rounded focus:outline-none border  border-gray-300 bg-white"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-span-full md:col-span-1">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Email :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 rounded focus:outline-none border border-gray-300 bg-white"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-span-full md:col-span-1">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Phone No. :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-300  bg-white"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                       Address :
                      </label>
                      <input
                        id="name"
                        type="textarea"
                        row="3"
                        className="h-20 mt-1 w-full px-2 focus:outline-none rounded border border-gray-300 bg-white"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-span-full md:col-span-1">
                      <label className="block text-gray-600 mb-1 ml-1">
                        State :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-300 bg-white"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-span-full md:col-span-1">
                      <label className="block text-gray-600 mb-1 ml-1">
                        City :
                      </label>
                                       <select value={city} onChange={handleCityChange}

                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-300 bg-white"
                        required
                      ><option value="">Select a city</option>
        {cities.map((city, index) => (
          <option key={index} value={city.name}>
            {city.name} 
          </option>
        ))}
      </select>

                    </div>

                    <div className="col-span-full md:col-span-1">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Zipcode :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-300 bg-white"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Pickup Charges :
                      </label>
                      
                    
                    <div className="relative h-10 ">
                          <span className="absolute inset-y-0 left-0 flex items-center p-2">
                            <button className="p-1 mt-2 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="h-10 mt-1 w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={pickupCharges}
        readOnly
                            // onChange={(e) => setPickupCharges(e.target.value)}
                            // required
                          />
                        </div>
                    </div>
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-2 ml-1">
                        Items :
                      </label>
                      {/* <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-300 bg-white"
                        value={items}
                        onChange={(e) => setItems(e.target.value)}
                        required
                      /> */}
<div className="grid grid-cols-1 md:grid-cols-3 gap-4 pl-1 ">
{options.map((option) => (
    <label className="flex gap-2" key={option.id}>
        <input type="checkbox"
        value={option.id}
        checked={items.includes(option.label)}
        onChange={()=> handleCheckboxChange(option.label)} className="w-4"/>
        {option.label}
    </label>
))}</div>
                    
                    </div>


                    
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Question/Comments :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-20 mt-1 w-full px-2 focus:outline-none rounded border border-gray-300 bg-white"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        
                      />
                    
                    </div>


                 
           

                <button
                          className="col-span-full bg-indigo-500 p-4 rounded-md text-white"
                          type="submit"
                        
                        >
                          Submit Request
                        </button>
                  </div>
                </form>
                {showAlert && (
                        <div className="fixed inset-0  flex items-center justify-center z-50">
                          <div className="fixed inset-0 bg-gray-800 bg-opacity-70 "></div>
                          <div className="bg-white p-6 w-2/4 rounded-lg z-10">
                            <Player
                              autoplay
                              loop={false}
                              src="https://assets10.lottiefiles.com/packages/lf20_a7kivydz.json"
                              style={{ height: "200px", width: "200px" }}
                              keepLastFrame
                            ></Player>
                            <h2 className="text-xl font-semibold mb-4 text-center ">
                              Request submitted succesfully
                            </h2>
                            <div className="flex justify-center">
                              <button
                                className="px-4 py-2 rounded-lg flex gap-2 justify-between items-center bg-gradient-to-b from-gray-500 to-gray-700 text-white mr-2"
                                onClick={() => {
                                  navigate("/all-application");
                                }}
                              >
                                Home
                                <FiChevronRight className="text-xl" />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
              </div>

            )}
    </div>
  )
}

export default NewPickup