import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  getAllApplications,
  deleteApplication,
} from "../actions/applicationAction";
import Loader from "./Loader";
import Sidebar from "./Sidebar";
import Signout from "./Signout";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { DELETE_APPLICATION_RESET } from "../constants/applicationConstants";
import { useAlert } from "react-alert";

const AllApplications = () => {
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.user);

  const { loading, error, applications } = useSelector(
    (state) => state.applications
  );

  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.application
  );

  const alert = useAlert();
  let navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (searchKeyword.trim()) {
      navigate(`/all-application/${searchKeyword}`);
    } else {
      navigate("/");
    }
  };

  const deleteApplicationHandler = (id) => {
    dispatch(deleteApplication(id));
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { keyword } = useParams();

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      alert.success("Application Deleted succesfully");
      dispatch({ type: DELETE_APPLICATION_RESET });
    }

    dispatch(getAllApplications(keyword));
  }, [dispatch, keyword, alert, error, deleteError, navigate, isDeleted]);

  const customSort = (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    if (dateA < dateB) return 1;
    else if (dateA > dateB) return -1;
    return 0;
  };

  applications.sort(customSort);

  const downloadCSV = () => {
    const header = [
      "S. No.",
      "AIN #",
      "Client Name",
      "Date of Application",
      "Email",
      "Phone",
      "Status",
    ];
    const rows = applications.map((a, index) => [
      `${index + 1}`,
      a.applicationNo,
      a.clientName,
      a.createdAt.slice(0, 10),
      a.email,
      a.phone,
      a.applicationStatus,
    ]);
    const csv = [header, ...rows].map((r) => r.join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "All-Applications.csv";
    link.click();
  };

  return (
    <Fragment>
      <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
        <Sidebar />
        <div className="m-3 w-full text-xl text-white font-semibold">
          <div className="grid grid-cols-2">
            <div className="">
              <h4 className="text-sm font-light">
                Applications / View All Applications
              </h4>
              <h2 className=" text-2xl font-semibold text-white leading-tight">
                All Request Applications
              </h2>
            </div>
            <div className="flex justify-self-end pr-8 self-center gap-8">
              {user.role === "superAdmin" && (
                <div className="relative">
                  <form className="search__box" onSubmit={submitHandler}>
                    <div className="relative">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <button
                          type="submit"
                          title="Search"
                          className="p-1 focus:outline-none focus:ring"
                        >
                          <BsSearch className="text-gray-800 text-base" />
                        </button>
                      </span>
                      <input
                        type="text"
                        name="Search"
                        placeholder="Search..."
                        value={searchKeyword}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        className="w-full py-2 pl-10 pr-2 text-sm font-normal rounded-md sm:w-full focus:outline-none bg-white text-gray-700"
                      />
                    </div>
                  </form>
                </div>
              )}
              <button
                onClick={downloadCSV}
                className="bg-white bg-gradient-to-b hover:from-slate-600 hover:to-slate-700 text-indigo-600 font-normal text-sm hover:text-white flex self-center gap-1 py-2 px-6 rounded-md"
              >
                Export CSV
              </button>
              <Signout />
            </div>
          </div>

          {/* <!-- component --> */}
          <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
            {loading ? (
              <Loader />
            ) : (
              <div className="">
                <div className="overflow-x-auto shadow-md max-h-[85vh] overflow-scroll scrollbar-hide rounded-2xl">
                  <table className="min-w-full text-xs ">
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col className="w-24" />
                    </colgroup>
                    <thead className="bg-white border-b border-opacity-20 sticky top-0 border-gray-900">
                      <tr className="text-left">
                        <th className="p-4">AIN #</th>
                        <th className="p-4">Client Name</th>
                        <th className="p-4">Date of Application</th>
                        <th className="p-4">Email</th>
                        <th className="p-4 text-right">Phone</th>
                        <th className="p-4 text-right w-36">Status</th>
                        {user.role === "superAdmin" && (
                          <th className="p-4 text-right">Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {applications.map((application) => {
                        // Format createdAt date
                        const createdAt = new Date(
                          application.createdAt
                        ).toLocaleDateString("en-GB");
                        return (
                          <tr
                            key={application._id}
                            className="border-b border-opacity-20 border-gray-900 bg-white hover:bg-indigo-50 cursor-pointer"
                          >
                            <td className="p-4">
                              <Link to={`/application/${application._id}`}>
                                <p>{application.applicationNo}</p>
                              </Link>
                            </td>
                            <td className="p-4">
                              <Link to={`/application/${application._id}`}>
                                <p>{application.clientName}</p>
                              </Link>
                            </td>
                            <td className="p-4">
                              <Link to={`/application/${application._id}`}>
                                <p>{createdAt}</p>
                              </Link>
                            </td>
                            <td className="p-4">
                              <Link to={`/application/${application._id}`}>
                                <p>{application.email}</p>
                              </Link>
                            </td>
                            <td className="p-4 text-right">
                              <Link to={`/application/${application._id}`}>
                                <p>{application.phone}</p>
                              </Link>
                            </td>
                            <td className="p-4 text-right">
                              <Link to={`/application/${application._id}`}>
                                <span
                                  className={`px-3 py-1 text-white font-semibold rounded-md ${
                                    application.applicationStatus ===
                                    "Processing"
                                      ? "bg-gradient-to-b from-orange-300 to-orange-400"
                                      : application.applicationStatus ===
                                        "Delivered"
                                      ? "bg-gradient-to-b from-green-400 to-green-500"
                                      : application.applicationStatus ===
                                        "In Review"
                                      ? "bg-gradient-to-b from-violet-300 to-violet-400"
                                      : application.applicationStatus ===
                                        "On Hold"
                                      ? " bg-gradient-to-b from-red-300 to-red-400"
                                      : application.applicationStatus ===
                                        "Rejected"
                                      ? "bg-gradient-to-b from-gray-400 to-gray-500 text-white"
                                      : application.applicationStatus ===
                                        "Approved"
                                      ? "bg-gradient-to-b from-green-400 to-green-500"
                                      : ""
                                  } text-gray-900`}
                                >
                                  <span>{application.applicationStatus}</span>
                                </span>
                              </Link>
                            </td>
                            {user.role === "superAdmin" && (
                              <td className="p-4 text-center flex justify-end">
                                <button  onClick={() =>
                                            deleteApplicationHandler(
                                              application._id
                                            )
                                          }>
                                  <AiOutlineDelete className="text-xl hover:text-red-500" />
                                </button>
                                {/* {isModalOpen && (
                                  <div className="fixed inset-0 flex items-center justify-center z-50">
                                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 "></div>
                                    <div className="bg-white p-6 rounded-lg z-10">
                                      <h2 className="text-xl font-semibold mb-4">
                                        Confirmation
                                      </h2>
                                      <p className="mb-4">
                                        Are you sure you want to delete this
                                        application?
                                      </p>
                                      <div className="flex justify-center">
                                        <button
                                          className="px-4 py-2 rounded-lg bg-red-500 text-white mr-2"
                                          onClick={() =>
                                            deleteApplicationHandler(
                                              application._id
                                            )
                                          }
                                        >
                                          Confirm
                                        </button>
                                        <button
                                          className="px-4 py-2 rounded-lg bg-gray-300 text-gray-800"
                                          onClick={() => setIsModalOpen(false)}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AllApplications;
