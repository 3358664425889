import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearErrors,
  getPickupDetails,
  updatePickupStatus as updateStatus,
} from "../actions/pickupAction";
import Loader from "./Loader";
import Signout from "./Signout";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { UPDATE_PICKUP_STATUS_RESET } from "../constants/pickupConstants";
import { BsCurrencyDollar } from "react-icons/bs";


import html2pdf from "html2pdf.js";

const Pickup = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { error, loading, pickup } = useSelector(
    (state) => state.pickupDetails
  );

  const { error: updateError, isUpdated } = useSelector(
    (state) => state.pickup
  );

  const { user } = useSelector((state) => state.user);

  const [pickupStatus, setPickupStatus] = useState("");

  const pickupId = id;

  const updatePickupStatus = (e) => {
    e.preventDefault();

    const myForm = new FormData();
    myForm.set("pickupStatus", pickupStatus);

    console.log(myForm);
    dispatch(updateStatus(pickupId, myForm));
  };


  const alert = useAlert();

  useEffect(() => {
    dispatch(getPickupDetails(id)).then(() => {});

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Application Status Updated succesfully");
      dispatch({ type: UPDATE_PICKUP_STATUS_RESET });
    }
  }, [dispatch, pickupId,id, alert, error, isUpdated, updateError]);

  const createdAt = new Date(pickup.createdAt).toLocaleDateString("en-GB");

  console.log(id);


  return (
    <Fragment>
      <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
        <Sidebar />
        <div className="m-3 w-full text-xl text-white font-semibold">
          <div className="grid grid-cols-2">
            <div className="">
              <h4 className="text-sm font-light">
                <Link to="/">All Pickup Requests</Link> / {pickup.name}
              </h4>
              <h2 className=" text-2xl font-semibold text-white leading-tight">
                Pickup Request
              </h2>
            </div>
            <div className="flex justify-self-end pr-8 self-center gap-8">

              <Signout />
            </div>
          </div>

          {/* <!-- component --> */}
          <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
            {loading ? (
              <Loader />
            ) : (
              <div className="  rounded-2xl shadow bg-white  mx-auto  text-gray-800">
                <form
                  id="pdf-content"
                  className=" w-full text-sm  p-8 mx-auto space-y-6 ng-untouched ng-pristine ng-valid"
                >
                  <div className="grid grid-cols-3 gap-8">
                    <div className=" "># AIN: {pickup.pickupNo}</div>
                    <div className=" ">Submitted On: {createdAt}</div>
                    <div className="text-end flex justify-end gap-2
                    ">Pickup Status: <h1  className={`${
                      pickup.pickupStatus ===
                      "Processing"
                        ? "text-orange-500"
                        : pickup.pickupStatus ===
                          "Pickup Scheduled"
                        ? "text-violet-500"
                        : pickup.pickupStatus ===
                          "Pickup Completed"
                        ? "text-green-500"
                        : ""
                    }`}> {pickup.pickupStatus}</h1></div>

                    
                  

                    <hr className="col-span-full border-dashed border-gray-500" />
                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Pickup Information
                    </div>
                    <div className="">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Full Name :
                      </label>
                      <input
                        id="carName"
                        type="text"
                        className="mt-1 w-full h-10 px-2 rounded focus:outline-none border  border-gray-200 bg-white"
                        value={pickup.name}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Email :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 rounded focus:outline-none border border-gray-200 bg-white"
                        value={pickup.email}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Phone No. :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200  bg-white"
                        value={pickup.phone}
                        readOnly
                      />
                    </div>
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                       Address :
                      </label>
                      <input
                        id="name"
                        type="textarea"
                        row="3"
                        className="h-20 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={pickup.address}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        State :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={pickup.state}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        City :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={pickup.city}
                        readOnly
                      />
                    </div>
                    <div className="">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Pickup Charges :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={ `$ ${pickup.pickupCharges}`}
                        readOnly
                      />
                    
                    </div>
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Items :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={pickup.items}
                        readOnly
                      />
                    
                    </div>


                    
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Question/Comments :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-20 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={pickup.comments}
                        readOnly
                      />
                    
                    </div>


                 
                    <div>

                    </div>
                  

                  </div>
                </form>
                {user.role === "superAdmin" && (
                  <div className="col-span-full gap-4 grid">
                    <hr className="border-dashed border-gray-500" />
                    <div className="gap-4 text-sm pt-4 pb-8 justify-center flex">
                      <select
                        className=" border p-4 border-gray-700"
                        name=""
                        id=""
                        onChange={(e) => setPickupStatus(e.target.value)}
                      >
                        <option value="">Update Pickup Status</option>
                        <option value="Pickup Completed">Pickup Completed</option>
                        <option value="Pickup Scheduled">Pickup Scheduled</option>
                        <option value="Pickup Rejected">Pickup Rejected</option>
                      </select>
                      <button
                        className="bg-indigo-500 py-4 text-white px-8"
                        onClick={updatePickupStatus}
                        disabled={
                          loading
                            ? true
                            : false || pickupStatus === ""
                            ? true
                            : false
                        }
                      >
                        Update
                      </button>
                    </div>
                  </div>
                )}
              </div>

              // </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Pickup;
