import React, { useState } from "react";
import { HiUser } from "react-icons/hi";
import { logout } from "../actions/userAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

const Signout = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  function logoutUser() {
    setLoading(true);
    dispatch(logout())
      .then(() => {
        alert.success("Logout Successful");
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        console.log("Logout error:", error);
      });
  }

  return (
    <button
      className="flex justify-self-end cursor-pointer self-center gap-2"
      onClick={logoutUser}
      disabled={isLoading}
    >
      <HiUser className="text-xl" />
      <h2 className="text-sm font-normal">Sign Out</h2>
    </button>
  );
};

export default Signout;
