import React from 'react'

const Loader = () => {
  return (
//     <div className="flex items-center justify-center space-x-2 h-[100vh]">
// 	<div className="w-4 h-4 rounded-full animate-pulse bg-[#2750A4]"></div>
// 	<div className="w-4 h-4 rounded-full animate-pulse bg-[#2750A4]"></div>
// 	<div className="w-4 h-4 rounded-full animate-pulse bg-[#2750A4]"></div>
// </div>


    <div className="flex items-center justify-center space-x-2 h-[100vh]">
<div
  class="inline-block h-14 w-14 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
  role="status">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...
    </span>
</div>
 </div>

  )
}

export default Loader