import React, { Fragment, useState, useEffect } from "react";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, resetPassword } from "../actions/userAction";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { Player } from "@lottiefiles/react-lottie-player";

const ResetPassword = () => {
  const { token } = useParams();

  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, success, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const resetPasswordSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("password", password);
    myForm.set("confirmPassword", confirmPassword);

    dispatch(resetPassword(token, myForm));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Password Updated Successfully");

      navigate("/login");
    }
  }, [dispatch, error, alert, navigate, success]);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="h-screen flex items-center justify-center">
          <form className="w-2/6" onSubmit={resetPasswordSubmit}>
            <Player
              autoplay
              loop
              src="https://assets6.lottiefiles.com/packages/lf20_jcpyfbsi.json"
              style={{ height: "200px", width: "200px" }}
            ></Player>

            <h2 className="text-center text-2xl font-bold mb-4">
              Reset Password
            </h2>
            <div className="relative mb-4">
              <div className="relative">
                <span className="absolute inset-y-0 right-0 flex items-center p-2 ">
                  <button
                    className="p-1 focus:outline-none"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleShowPassword();
                    }}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible className="text-indigo-500 text-base" />
                    ) : (
                      <AiOutlineEye className="text-indigo-500 text-base" />
                    )}
                  </button>
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full py-3 pr-10 pl-4 text-sm font-normal focus:outline-none bg-white text-gray-700 block p-2 rounded border border-gray-200"
                />
              </div>
            </div>

            <div className="relative mb-4">
              <div className="relative">
                <span className="absolute inset-y-0 right-0 flex items-center p-2 ">
                  <button
                    className="p-1 focus:outline-none"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleShowPassword();
                    }}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible className="text-indigo-500 text-base" />
                    ) : (
                      <AiOutlineEye className="text-indigo-500 text-base" />
                    )}
                  </button>
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full py-3 pr-10 pl-4 text-sm font-normal focus:outline-none bg-white text-gray-700 block p-2 rounded border border-gray-200"
                />
              </div>
            </div>
            <button
              type="submit"
              className="p-2 bg-indigo-500 w-full text-white"
            >
              Update Password
            </button>
          </form>
        </div>
        // </div>
      )}
    </Fragment>
  );
};

export default ResetPassword;
