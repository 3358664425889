import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearErrors,
  getApplicationDetails,
  updateApplicationStatus as updateStatus,
} from "../actions/applicationAction";
import Loader from "./Loader";
import Signout from "./Signout";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { UPDATE_APPLICATION_STATUS_RESET } from "../constants/applicationConstants";

import html2pdf from "html2pdf.js";

const Application = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { error, loading, application } = useSelector(
    (state) => state.applicationDetails
  );

  const { error: updateError, isUpdated } = useSelector(
    (state) => state.application
  );

  const { user } = useSelector((state) => state.user);

  const [applicationStatus, setApplicationStatus] = useState("");

  const applicationId = id;

  const updateApplicationStatus = (e) => {
    e.preventDefault();

    const myForm = new FormData();
    myForm.set("applicationStatus", applicationStatus);

    console.log(myForm);
    dispatch(updateStatus(applicationId, myForm));
  };

  console.log(applicationStatus);

  const alert = useAlert();

  useEffect(() => {
    dispatch(getApplicationDetails(id)).then(() => {});

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Application Status Updated succesfully");
      dispatch({ type: UPDATE_APPLICATION_STATUS_RESET });
    }
  }, [dispatch, applicationId,id, alert, error, isUpdated, updateError]);

  const createdAt = new Date(application.createdAt).toLocaleDateString("en-GB");

  console.log(id);

  const handlePDFDownload = () => {
    const element = document.getElementById("pdf-content");

    html2pdf()
      .set({
        filename: `Application_${application.clientName}.pdf`,
        margin: 0,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .from(element)
      .save();
  };

  return (
    <Fragment>
      <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
        <Sidebar />
        <div className="m-3 w-full text-xl text-white font-semibold">
          <div className="grid grid-cols-2">
            <div className="">
              <h4 className="text-sm font-light">
                <Link to="/">All Application</Link> / {application.clientName}
              </h4>
              <h2 className=" text-2xl font-semibold text-white leading-tight">
                Request Application
              </h2>
            </div>
            <div className="flex justify-self-end pr-8 self-center gap-8">
              <button
                // onClick={downloadCSV}
                className="bg-white hover:bg-indigo-600 text-indigo-600 font-normal text-sm hover:text-white flex self-center gap-1 py-2 px-6 rounded-md"
                onClick={handlePDFDownload}
              >
                Download PDF
              </button>
              <Signout />
            </div>
          </div>

          {/* <!-- component --> */}
          <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
            {loading ? (
              <Loader />
            ) : (
              <div className="  rounded-2xl shadow bg-white  mx-auto  text-gray-800">
                <form
                  id="pdf-content"
                  className=" w-full text-sm  p-8 mx-auto space-y-6 ng-untouched ng-pristine ng-valid"
                >
                  <div className="grid grid-cols-3 gap-8">
                    <div className=" "># AIN: {application.applicationNo}</div>
                    <div className=" ">Submitted On: {createdAt}</div>

                    <div className="text-end">
                      Phone No.:{" "}
                      {application &&
                        application.user &&
                        application.user.phone}
                    </div>
                    <div className=" ">
                      Agency:{" "}
                      {application &&
                        application.user &&
                        application.user.agency}
                    </div>
                    <div className="">
                      Case Manager:{" "}
                      {application &&
                        application.user &&
                        application.user.caseManagerName}
                    </div>

                    <hr className="col-span-full border-dashed border-gray-500" />
                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Client Information
                    </div>
                    <div className="">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Client Name :
                      </label>
                      <input
                        id="carName"
                        type="text"
                        className="mt-1 w-full h-10 px-2 rounded focus:outline-none border  border-gray-200 bg-white"
                        value={application.clientName}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Email :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 rounded focus:outline-none border border-gray-200 bg-white"
                        value={application.email}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Phone No. :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200  bg-white"
                        value={application.phone}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Marital Status :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.maritalStatus}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Race/Ethnicity :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.ethnicity}
                        readOnly
                      />
                    </div>
                    <div></div>
                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Client Location
                    </div>

                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Address :
                      </label>
                      <textarea
                        rows="2"
                        id="name"
                        type="text"
                        className="h-24 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.clientAddress}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Floor No. :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.floorNo}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        State :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.clientState}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        City :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.clientCity}
                        readOnly
                      />
                    </div>

                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Zip code :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.clientZipcode}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Stairs :
                      </label>
                      <input
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded autoexpand border border-gray-200 bg-white"
                        value={application.stairs}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Elevator :
                      </label>
                      <input
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded autoexpand border border-gray-200 bg-white"
                        value={application.elevator}
                        readOnly
                      />
                    </div>
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Total no. of live at client location :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.totalLive}
                        readOnly
                      />
                    </div>
                    <div className="col-span-full">
                      <label className="block text-gray-600  mb-1 ml-1">
                        All lives names & ages :
                      </label>
                      <textarea
                        rows="2"
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.namesAges}
                        readOnly
                      />
                    </div>

                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Financial Household Monthly Income
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Income from work :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.incomeWork}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Income from vet benefits :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.incomeVetBenefits}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Income from TANF :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.incomeTANF}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Income from food stamps :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.incomeFoodStamps}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Income from SSI/SSD :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.incomeSsiSsd}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Income from child support :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.incomeChildSupport}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Other Income :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.otherIncome}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Total Income :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={
                          application.incomeWork +
                          application.incomeVetBenefits +
                          application.incomeTANF +
                          application.incomeFoodStamps +
                          application.incomeSsiSsd +
                          application.incomeChildSupport +
                          application.otherIncome
                        }
                        readOnly
                      />
                    </div>
                    <div className=""></div>

                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Household Monthly Payments
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Rent/Mortgage Payment :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.rentMortgage}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Gas/Oil Bill :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.gasOil}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Electricity Bill :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.electricity}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Cable Bill :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.cable}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Phone Bill :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.phoneBill}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Food Expenses :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.food}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Other Payments :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.otherPayments}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Total Monthly Payments :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={
                          application.rentMortgage +
                          application.gasOil +
                          application.electricity +
                          application.cable +
                          application.phoneBill +
                          application.food +
                          application.otherPayments
                        }
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Reasons for referral :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.reasonReferral}
                        readOnly
                      />
                    </div>

                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Furnitures Requested
                    </div>
                    <div className="col-span-full text-indigo-500 text-lg">
                      Bedroom
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 1 :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed1}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 2 :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed2}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 3 :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed3}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 1 Mattress :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed1Mattress}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 2 Mattress :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed2Mattress}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 3 Mattress :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed3Mattress}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 1 Box Spring :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed1Spring}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 2 Box Spring :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed2Spring}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 3 Box Spring :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed3Spring}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 1 Frame :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed1Frame}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 2 Frame :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed2Frame}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 3 Frame :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed3Frame}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 4 :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed4}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 5 :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed5}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed Linens :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bedLinens}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 4 Mattress :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed4Mattress}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 5 Mattress :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed5Mattress}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Dresser :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.dresser}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 4 Box Spring :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed4Spring}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 5 Box Spring :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed5Spring}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Nightstand :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.nightStand}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 4 Frame :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed4Frame}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Bed 5 Frame :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bed5Frame}
                        readOnly
                      />
                    </div>

                    <div className="col-span-full text-indigo-500 text-lg">
                      Living Room
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Sofa or Loveseat :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.sofeLoveseat}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Armchair :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.armChair}
                        readOnly
                      />
                    </div>

                    <div className="col-span-full text-indigo-500 text-lg">
                      Electronics
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        TV :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.tv}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        TV Stand :
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.tvStand}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        End Table :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.endTable}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Floor Lamp :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.floorLamp}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Table Lamp :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.tableLamp}
                        readOnly
                      />
                    </div>

                    <div className="col-span-full text-indigo-500 text-lg">
                      Study
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Book Shelf :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.bookshelf}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Desk :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.desk}
                        readOnly
                      />
                    </div>

                    <div className="col-span-full text-indigo-500 text-lg">
                      Kitchen
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Kitchen Table :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.kitchenTable}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Kitchen Chairs :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.kitchenChairs}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Dishes :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.dishes}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Silverware :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.silverware}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Pots & Pans :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.potsPans}
                        readOnly
                      />
                    </div>
                    <div className="col-span-full text-indigo-500 text-lg">
                      Other{" "}
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Other not listed :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.notListed}
                        readOnly
                      />
                    </div>
                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Reason For Referral
                    </div>
                    <div className="col-span-full">
                      <textarea
                        rows="3"
                        id="name"
                        type="text"
                        className="h-24 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.reasonForReferral}
                        readOnly
                      />
                    </div>
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                        How did you hear about us :{" "}
                      </label>
                      <textarea
                        rows="3"
                        id="name"
                        type="text"
                        className="h-24 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.howDidYouHearAboutUs}
                        readOnly
                      />
                    </div>
                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Delivery Instructions
                    </div>
                    <div className="col-span-full">
                      <textarea
                        rows="3"
                        id="name"
                        type="text"
                        className="h-24 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.deliveryInstructions}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Total Client Contribution :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.totalClientContribution}
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-gray-600 mb-1 ml-1">
                        Total Agency Contribution :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.totalAgencyContribution}
                        readOnly
                      />
                    </div>

                    <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                      Declaration
                    </div>
                    <div className="col-span-full">
                      <label className="block text-gray-600 mb-1 ml-1">
                        Agreed Terms & Conditions :{" "}
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="h-10 mt-1 w-full px-2 focus:outline-none rounded border border-gray-200 bg-white"
                        value={application.tAndC}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                {user.role === "superAdmin" && (
                  <div className="col-span-full gap-4 grid">
                    <hr className="border-dashed border-gray-500" />
                    <div className="gap-4 text-sm pt-4 pb-8 justify-center flex">
                      <select
                        className=" border p-4 border-gray-700"
                        name=""
                        id=""
                        onChange={(e) => setApplicationStatus(e.target.value)}
                      >
                        <option value="">Update Status</option>
                        <option value="In Review">In Review</option>
                        <option value="Delivered">Delivered</option>
                        <option value="On Hold">On Hold</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Approved">Approved</option>
                      </select>
                      <button
                        className="bg-indigo-500 py-4 text-white px-8"
                        onClick={updateApplicationStatus}
                        disabled={
                          loading
                            ? true
                            : false || applicationStatus === ""
                            ? true
                            : false
                        }
                      >
                        Update
                      </button>
                    </div>
                  </div>
                )}
              </div>

              // </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Application;
