import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from 'react-router-dom';

import { positions, transitions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";


const options = {
  timeout: 6000,
  // position: positions.BOTTOM_CENTER,
  position: positions.TOP_CENTER,
  transition: transitions.SCALE,
};

const containerStyle = {
  textTransform: "capitalize", // Capitalize the alert message
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <React.StrictMode>
  <AlertProvider template={AlertTemplate} {...options} containerStyle={containerStyle}>
  <BrowserRouter>
    <App />
    </BrowserRouter>
    </AlertProvider>
  </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

