import {
  ALL_PICKUP_FAIL,
  ALL_PICKUP_REQUEST,
  ALL_PICKUP_SUCCESS,
  PICKUP_DETAILS_REQUEST,
  PICKUP_DETAILS_SUCCESS,
  PICKUP_DETAILS_FAIL,
  UPDATE_PICKUP_STATUS_REQUEST,
  UPDATE_PICKUP_STATUS_SUCCESS,
  UPDATE_PICKUP_STATUS_FAIL, UPDATE_PICKUP_STATUS_RESET,
  NEW_PICKUP_FAIL,
  NEW_PICKUP_REQUEST,
  NEW_PICKUP_RESET,
  NEW_PICKUP_SUCCESS,
  DELETE_PICKUP_FAIL,
  DELETE_PICKUP_REQUEST,
  DELETE_PICKUP_RESET,
  DELETE_PICKUP_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/pickupConstants";


export const pickupsReducer = (state = { pickups: [] }, action) => {

  switch (action.type) {
    case ALL_PICKUP_REQUEST:
      return {
        loading: true,
        pickups: []
      };

    case ALL_PICKUP_SUCCESS:
      return {
        loading: false,
        pickups: action.payload,
        pickupCount: action.payload.pickupCount,
      };
    case ALL_PICKUP_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}


export const pickupDetailsReducer = (state = { pickup: {} }, action) => {

  switch (action.type) {
    case PICKUP_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case PICKUP_DETAILS_SUCCESS:
      return {
        loading: false,
        pickup: action.payload,
      };
    case PICKUP_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};




export const pickupReducer = (state = { pickup: {} }, action) => {
  switch (action.type) {
    case DELETE_PICKUP_REQUEST:
    case UPDATE_PICKUP_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PICKUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case UPDATE_PICKUP_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PICKUP_FAIL:
    case UPDATE_PICKUP_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PICKUP_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PICKUP_STATUS_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


export const newPickupReducer = (state = { pickup: {} }, action) => {

  switch (action.type) {
    case NEW_PICKUP_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case NEW_PICKUP_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        pickup: action.payload.pickup,
      };
    case NEW_PICKUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PICKUP_RESET:
      return {
        ...state,
        success: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


