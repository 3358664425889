import {
  ALL_APPLICATION_FAIL,
  ALL_APPLICATION_REQUEST,
  ALL_APPLICATION_SUCCESS,
  APPLICATION_DETAILS_REQUEST,
  APPLICATION_DETAILS_SUCCESS,
  APPLICATION_DETAILS_FAIL,
  UPDATE_APPLICATION_STATUS_REQUEST,
  UPDATE_APPLICATION_STATUS_SUCCESS,
  UPDATE_APPLICATION_STATUS_FAIL, UPDATE_APPLICATION_STATUS_RESET,
  NEW_APPLICATION_FAIL,
  NEW_APPLICATION_REQUEST,
  NEW_APPLICATION_RESET,
  NEW_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAIL,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_RESET,
  DELETE_APPLICATION_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/applicationConstants";


export const applicationsReducer = (state = { applications: [] }, action) => {

  switch (action.type) {
    case ALL_APPLICATION_REQUEST:
      return {
        loading: true,
        applications: []
      };

    case ALL_APPLICATION_SUCCESS:
      return {
        loading: false,
        applications: action.payload,
        applicationCount: action.payload.applicationCount,
      };
    case ALL_APPLICATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}


export const applicationDetailsReducer = (state = { application: {} }, action) => {

  switch (action.type) {
    case APPLICATION_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case APPLICATION_DETAILS_SUCCESS:
      return {
        loading: false,
        application: action.payload,
      };
    case APPLICATION_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};




export const applicationReducer = (state = { application: {} }, action) => {
  switch (action.type) {
    case DELETE_APPLICATION_REQUEST:
    case UPDATE_APPLICATION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case UPDATE_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_APPLICATION_FAIL:
    case UPDATE_APPLICATION_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_APPLICATION_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_APPLICATION_STATUS_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


export const newApplicationReducer = (state = { application: {} }, action) => {

  switch (action.type) {
    case NEW_APPLICATION_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case NEW_APPLICATION_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        application: action.payload.application,
      };
    case NEW_APPLICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_APPLICATION_RESET:
      return {
        ...state,
        success: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


