import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userAction";
import Loader from "./Loader";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdAlternateEmail } from "react-icons/md";

const Login = () => {
  let navigate = useNavigate();

  const { loading, isAuthenticated } = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    dispatch(login(email, password));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [dispatch, navigate, isAuthenticated]);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="bg-gray-50 min-h-screen flex items-center justify-center">
          {/* <!-- login container --> */}
          <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
            {/* <!-- form --> */}
            <div className="md:w-1/2 px-8 md:px-12">
              <h2 className="font-bold text-2xl text-[#002D74]">Login</h2>
              <p className="text-xs mt-4 text-[#002D74]">
                If you are already a member, easily log in
              </p>

              <form
                action=""
                className="flex flex-col gap-4"
                onSubmit={handleLogin}
              >
                <div className="relative  mt-8 ">
                  <div className="relative">
                    <span className="absolute inset-y-0 right-0 flex items-center p-2 ">
                      <button className="p-1 focus:outline-none">
                        <MdAlternateEmail className="text-gray-500 text-base" />
                      </button>
                    </span>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={onChangeEmail}
                      className="w-full py-2 pr-10  font-normal focus:outline-none bg-white text-gray-700 block p-2 rounded-xl  border border-gray-200"
                    />
                  </div>
                </div>

                <div className="relative mb-4">
                  <div className="relative">
                    <span className="absolute inset-y-0 right-0 flex items-center p-2 ">
                      <button
                        className="p-1 focus:outline-none"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleShowPassword();
                        }}
                      >
                        {showPassword ? (
                          <AiOutlineEyeInvisible className="text-gray-500 text-base" />
                        ) : (
                          <AiOutlineEye className="text-gray-500 text-base" />
                        )}
                      </button>
                    </span>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      required
                      autoComplete="current-password"
                      value={password}
                      onChange={onChangePassword}
                      className="w-full py-2 pr-10   font-normal focus:outline-none bg-white text-gray-700 block p-2 rounded-xl  border border-gray-200"
                    />
                  </div>
                </div>

                <button className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300">
                  Login
                </button>
              </form>

              <div className="mt-5 text-xs py-4 text-[#002D74]">
                <Link to="/password/forgot">Forgot your password?</Link>
              </div>
            </div>

            {/* <!-- image --> */}
            <div className="md:block hidden w-1/2">
              <img
                className="rounded-2xl h-[75vh] object-cover"
                src="https://images.pexels.com/photos/6591155/pexels-photo-6591155.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt="Art"
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;