

const PhoneScreen = () => {
  return (
    <div>
        <div className="bg-gray-900 h-screen text-center p-4 grid text-white">

            <div className=' self-center '>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-20 mx-auto my-4 h-20 text-orange-400">
  <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
</svg>

                Cannot Access Admin Dashboard in mobile phone, please Login from Pc or Laptop to access this page.</div>
                
        </div>
    </div>
  )
}

export default PhoneScreen