import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearErrors, getUserDetails, updateUser } from "../actions/userAction";
import Loader from "./Loader";
import Signout from "./Signout";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { UPDATE_USER_RESET } from "../constants/userConstants";

const User = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { error, loading, user } = useSelector((state) => state.userDetails);

  const { error: updateError, isUpdated } = useSelector(
    (state) => state.profile
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [role, setRole] = useState("");
  const [caseManagerName, setCaseManagerName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [agency, setAgency] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");

  const userId = id;

  const alert = useAlert();

  useEffect(() => {
    if (user && user._id !== userId) {
      dispatch(getUserDetails(userId));
    } else if (user) {
      setRole(user.role);
      setCaseManagerName(user.caseManagerName);
      setEmail(user.email);
      setPhone(user.phone);
      setAgency(user.agency);
      setAddress(user.address);
      setState(user.state);
      setCity(user.city);
      setZipcode(user.zipcode);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, alert, error, user, userId]);

  useEffect(() => {
    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }
    // Check if the user data was updated
    if (isUpdated) {
      alert.success("User Information Updated succesfully");
      // Fetch updated user details
      dispatch(getUserDetails(userId));
      // Reset the update status
      dispatch({ type: UPDATE_USER_RESET });
    }
  }, [dispatch,alert, isUpdated, updateError, userId]);

  console.log(address);

  const [editMode, setEditMode] = useState(false);

  const updateProfileInformation = (e) => {
    e.preventDefault();

    const myForm = new FormData();
    myForm.set("agency", agency);
    myForm.set("role", role);
    myForm.set("email", email);
    myForm.set("phone", phone);
    myForm.set("caseManagerName", caseManagerName);
    myForm.set("address", address);
    myForm.set("state", state);
    myForm.set("city", city);
    myForm.set("zipcode", zipcode);

    console.log(myForm);
    dispatch(updateUser(userId, myForm));
    setIsModalOpen(false);
    setEditMode(false);
  };

  const createdAt = new Date(user.createdAt).toLocaleDateString("en-GB");

  return (
    <Fragment>
      <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
        <Sidebar />
        <div className="m-3 w-full text-xl text-white font-semibold">
          <div className="grid grid-cols-2">
            <div className="">
              <h4 className="text-sm font-light">
                <Link to="/">All Users</Link> / {user.caseManagerName}
              </h4>
              <h2 className=" text-2xl font-semibold text-white leading-tight">
                User Information
              </h2>
            </div>
            <div className="flex justify-self-end pr-8 self-center gap-8">
              <button
                // onClick={downloadCSV}
                className="bg-white hover:bg-indigo-600 text-indigo-600 font-normal text-sm hover:text-white flex self-center gap-1 py-2 px-6 rounded-md"
              >
                Export CSV
              </button>
              <Signout />
            </div>
          </div>

          {/* <!-- component --> */}
          <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
            {loading ? (
              <Loader />
            ) : (
              <div className="overflow-x-auto shadow-md overflow-scroll scrollbar-hide rounded-2xl">
                <div className="  mx-auto  text-gray-800">
                  <form className=" w-full text-sm  p-8 mx-auto space-y-6 rounded-2xl shadow bg-white ng-untouched ng-pristine ng-valid">
                    <div className="grid grid-cols-3 gap-8">
                      <div className="col-span- text-indigo-600 text-2xl font-bold leading-tight">
                        Profile Information
                      </div>
                      <div className="flex justify-end items-center gap-3">
                        <label className="block text-gray-600 mb-1 ml-1">
                          User Role :
                        </label>
                        <select
                          id="name"
                          type="text"
                          className={`block text-center p-2 focus:outline-none rounded border border-gray-200 bg-white ${
                            role === "superAdmin"
                              ? "bg-gradient-to-b from-green-400 to-green-500"
                              : role === "admin"
                              ? "bg-gradient-to-b from-blue-300 to-blue-400"
                              : ""
                          }`}
                          value={role}
                          disabled={!editMode}
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="superAdmin">MAHF Admin</option>
                          <option value="admin">Agency Admin</option>
                        </select>
                      </div>
                      <div className="flex gap-4 justify-end">
                        {editMode && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setIsModalOpen(true);
                            }}
                            className="bg-indigo-600 hover:bg-indigo-700 text-center flex justify-center self-center text-white font-normal text-sm py-2 px-6 rounded-md"
                          >
                            Update
                          </button>
                        )}
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setEditMode(!editMode);
                          }}
                          className="bg-gray-300 w-2/5 hover:bg-indigo-500 text-gray-600 text-center font-normal text-sm hover:text-white flex self-center justify-center py-2 px-6 rounded-md"
                        >
                          {editMode ? "Cancel" : "Edit Profile"}
                        </button>
                      </div>

                      <hr className="col-span-full border-dashed border-gray-500" />
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Case Manager :
                        </label>
                        <input
                          id="carName"
                          type="text"
                          className="block w-full p-2 rounded focus:outline-none border border-gray-200 bg-white"
                          value={caseManagerName}
                          readOnly={!editMode}
                          onChange={(e) => setCaseManagerName(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Email :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 rounded focus:outline-none border border-gray-200 bg-white"
                          value={email}
                          readOnly={!editMode}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Phone No. :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={phone}
                          readOnly={!editMode}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Agency :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={agency}
                          readOnly={!editMode}
                          onChange={(e) => setAgency(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          UIN :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={user._id}
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Date :
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded autoexpand border border-gray-200 bg-white"
                          value={createdAt}
                          readOnly
                        />
                      </div>

                      <div className="col-span-full">
                        <label className="block text-gray-600 mb-1 ml-1">
                          Address :
                        </label>
                        <textarea
                          rows="2"
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={address}
                          readOnly={!editMode}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          State :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={state}
                          readOnly={!editMode}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          City :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={city}
                          readOnly={!editMode}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Zip code :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={zipcode}
                          readOnly={!editMode}
                          onChange={(e) => setZipcode(e.target.value)}
                        />
                      </div>
                      {isModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 "></div>
                          <div className="bg-white p-6 rounded-lg z-10">
                            <h2 className="text-xl font-semibold mb-4">
                              Confirmation
                            </h2>
                            <p className="mb-4">
                              Are you sure you want to update information of
                              this user?
                            </p>
                            <div className="flex justify-center">
                              <button
                                className="px-4 py-2 rounded-lg bg-red-500 text-white mr-2"
                                onClick={updateProfileInformation}
                              >
                                Confirm
                              </button>
                              <button
                                className="px-4 py-2 rounded-lg bg-gray-300 text-gray-800"
                                onClick={() => setIsModalOpen(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default User;
