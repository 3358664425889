export const ALL_APPLICATION_REQUEST="ALL_APPLICATION_REQUEST";
export const ALL_APPLICATION_SUCCESS="ALL_APPLICATION_SUCCESS";
export const ALL_APPLICATION_FAIL="ALL_APPLICATION_FAIL";

// export const ADMIN_CAR_REQUEST="ADMIN_CAR_REQUEST";
// export const ADMIN_CAR_SUCCESS="ADMIN_CAR_SUCCESS";
// export const ADMIN_CAR_FAIL="ADMIN_CAR_FAIL";

export const NEW_APPLICATION_REQUEST="NEW_APPLICATION_REQUEST";
export const NEW_APPLICATION_SUCCESS="NEW_APPLICATION_SUCCESS";
export const NEW_APPLICATION_RESET="NEW_APPLICATION_RESET";
export const NEW_APPLICATION_FAIL="NEW_APPLICATION_FAIL";


export const UPDATE_APPLICATION_STATUS_REQUEST="UPDATE_APPLICATION_STATUS_REQUEST";
export const UPDATE_APPLICATION_STATUS_SUCCESS="UPDATE_APPLICATION_STATUS_SUCCESS";
export const UPDATE_APPLICATION_STATUS_RESET="UPDATE_APPLICATION_STATUS_RESET";
export const UPDATE_APPLICATION_STATUS_FAIL="UPDATE_APPLICATION_STATUS_FAIL";

export const DELETE_APPLICATION_REQUEST="DELETE_APPLICATION_REQUEST";
export const DELETE_APPLICATION_SUCCESS="DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_RESET="DELETE_APPLICATION_RESET";
export const DELETE_APPLICATION_FAIL="DELETE_APPLICATION_FAIL";

export const APPLICATION_DETAILS_REQUEST="APPLICATION_DETAILS_REQUEST";
export const APPLICATION_DETAILS_SUCCESS="APPLICATION_DETAILS_SUCCESS";
export const APPLICATION_DETAILS_FAIL="APPLICATION_DETAILS_FAIL";

export const CLEAR_ERRORS="CLEAR_ERRORS";