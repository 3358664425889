import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Signout from "./Signout";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import { clearErrors, getAllApplications } from "../actions/applicationAction";
import {
  AiOutlineFileSearch,
  AiOutlineFileDone,
  AiOutlineFileUnknown,
  AiOutlineFileExcel,
  AiOutlineFileExclamation,
} from "react-icons/ai";
import { BsFillSuitHeartFill, BsTruck , BsCheckCircle, BsQuestionCircle} from "react-icons/bs";
import { HiOutlineUsers, HiOutlineUserGroup } from "react-icons/hi2";
import {  FiUserCheck } from "react-icons/fi";
import {  MdOutlineCancel } from "react-icons/md";

import { useAlert } from "react-alert";
import { getAllUsers } from "../actions/userAction";
import { getAllPickups } from "../actions/pickupAction";

import PhoneScreen from "./PhoneScreen";

const Dashboard = () => {
  const { loading, error, applications } = useSelector(
    (state) => state.applications
  );
  const { users } = useSelector((state) => state.allUsers);
  const { user } = useSelector((state) => state.user);
  const { pickups } = useSelector((state) => state.pickups);

  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(getAllApplications());
    dispatch(getAllUsers());
    dispatch(getAllPickups());
  }, [dispatch, alert, error]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      {width < 1030 ? (
        <PhoneScreen />
      ) : (
        <section className="flex gap-6">
          <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
          <Sidebar />
          <div className="m-3 w-full text-xl text-white font-semibold">
            <div className="grid grid-cols-2">
              <div className="">
                <h4 className="text-sm font-light">
                  Assistance Management System / Dashboard
                </h4>
                <h2 className=" text-2xl font-semibold text-white leading-tight">
                  Overview
                </h2>
              </div>
              <div className="flex justify-self-end pr-8 self-center gap-8">
                <Signout />
              </div>
            </div>

            {/* <!-- component --> */}
            <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
              {loading ? (
                <Loader />
              ) : (
                <div className="overflow-x-auto   overflow-scroll scrollbar-hide ">
                  <div className="  mx-auto grid gap-4  text-gray-800">
                    <div className="grid grid-cols-4 gap-8">
                      <div
                        className={`grid ${
                          user.role === "admin" && "col-span-full"
                        } grid-cols-2 bg-white rounded-xl p-6 shadow-md  font-bold `}
                      >
                        <div className="">
                          <h3 className="text-sm text-gray-500">
                            TOTAL <br /> APPLICATIONS
                          </h3>
                          <h1 className="text-6xl">
                            {applications && applications.length}
                          </h1>
                        </div>
                        <div className="self-center bg-gradient-to-b from-indigo-500 to-indigo-600 p-4 w-20 h-20 items-center flex rounded-full justify-center justify-self-end">
                          <AiOutlineFileSearch className="text-4xl text-white" />
                        </div>
                      </div>
                      {user.role === "superAdmin" && (
                        <div className="grid grid-cols-2 bg-white rounded-xl p-6 shadow-md  font-bold ">
                          <div className="">
                            <h3 className="text-sm text-gray-500">
                              TOTAL <br /> USERS
                            </h3>
                            <h1 className="text-6xl">
                              {users && users.length}
                            </h1>
                          </div>
                          <div className=" self-center bg-gradient-to-b from-orange-500 to-red-600 p-4 w-20 h-20 items-center flex rounded-full justify-center justify-self-end">
                            <HiOutlineUserGroup className="text-4xl text-white" />
                          </div>
                        </div>
                      )}

                      {user.role === "superAdmin" && (
                        <div className="grid grid-cols-2 bg-white rounded-xl p-6 shadow-md  font-bold ">
                          <div className="">
                            <h3 className="text-sm text-gray-500">
                              AGENCY <br /> USERS
                            </h3>
                            <h1 className="text-6xl">
                              {
                                users.filter((users) => users.role === "admin")
                                  .length
                              }
                            </h1>
                          </div>
                          <div className="self-center bg-gradient-to-b from-teal-500 to-teal-600 p-4 w-20 h-20 items-center flex rounded-full justify-center justify-self-end">
                            <HiOutlineUsers className="text-4xl text-white" />
                          </div>
                        </div>
                      )}

                      {user.role === "superAdmin" && (
                        <div className="grid grid-cols-2 bg-white rounded-xl p-6 shadow-md  font-bold ">
                          <div className="">
                            <h3 className="text-sm text-gray-500">
                              MAHF <br /> USERS
                            </h3>
                            <h1 className="text-6xl">
                              {
                                users.filter(
                                  (users) => users.role === "superAdmin"
                                ).length
                              }
                            </h1>
                          </div>
                          <div className="self-center bg-gradient-to-b from-orange-400 to-orange-600 p-4 w-20 h-20 items-center flex rounded-full justify-center justify-self-end">
                            <FiUserCheck className="text-4xl text-white" />
                          </div>
                        </div>
                      )}
                    </div>
                    {user.role === "superAdmin" && (
                    <div className="p-1  drop-shadow">
                      <div className="grid grid-cols-2 bg-white rounded-xl gap-6 p-8">
                        <div className="col-span-full font-normal text-xl">Pickups</div>
                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <BsTruck className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                            {pickups && pickups.length}
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                              Total Pickup Requests
                            </h3>
                          </div>
                        </div>

                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <BsCheckCircle className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                pickups.filter(
                                  (pickup) =>
                                  pickup.pickupStatus ===
                                    "Pickup Completed"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                            Pickups Completed
                            </h3>
                          </div>
                        </div>

                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <MdOutlineCancel className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                pickups.filter(
                                  (pickup) =>
                                  pickup.pickupStatus === "Pickup Rejected"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                            Pickups Rejected
                            </h3>
                          </div>
                        </div>

                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <BsQuestionCircle className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                pickups.filter(
                                  (pickup) =>
                                  pickup.pickupStatus ===
                                    "Processing"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                            Pickup Requests Pending
                            </h3>
                          </div>
                        </div>

             
                      </div>
                    </div>
)}


                    <div className="p-1  drop-shadow">
                      <div className="grid grid-cols-2 bg-white rounded-xl gap-6 p-8">
                      <div className="col-span-full font-normal text-xl">Assistances</div>
                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <AiOutlineFileDone className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                applications.filter(
                                  (application) =>
                                    application.applicationStatus === "Approved"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                              Application Approved
                            </h3>
                          </div>
                        </div>

                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <AiOutlineFileUnknown className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                applications.filter(
                                  (application) =>
                                    application.applicationStatus ===
                                    "Processing"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                              Application Pending
                            </h3>
                          </div>
                        </div>

                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <AiOutlineFileExcel className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                applications.filter(
                                  (application) =>
                                    application.applicationStatus === "Rejected"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                              Application Rejected
                            </h3>
                          </div>
                        </div>

                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <AiOutlineFileSearch className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                applications.filter(
                                  (application) =>
                                    application.applicationStatus ===
                                    "In Review"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                              Application In Review
                            </h3>
                          </div>
                        </div>

                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <BsTruck className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                applications.filter(
                                  (application) =>
                                    application.applicationStatus ===
                                    "Delivered"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                              Deliveries Fulfilled
                            </h3>
                          </div>
                        </div>

                        <div className=" flex items-center gap-3">
                          <div className="bg-gradient-to-b from-gray-600 to-gray-800 p-2 w-12 h-12 items-center flex rounded-2xl justify-center justify-self-start">
                            <AiOutlineFileExclamation className="text-2xl text-white" />
                          </div>
                          <div className=" items-center  gap-2">
                            <h3 className=" text-slate-700 text-3xl">
                              {
                                applications.filter(
                                  (application) =>
                                    application.applicationStatus === "On Hold"
                                ).length
                              }
                            </h3>
                            <h3 className="text-sm font-medium text-gray-600">
                              Application On Hold
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end items-center gap-1 p-6 pr-12 text-sm  font-light text-gray-600">
                    <span className="mr-1">
                      © 2023, made with{" "}
                      <BsFillSuitHeartFill className="inline" />
                    </span>{" "}
                    by
                    <strong>Truth2it</strong>.
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default Dashboard;
