import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import Sidebar from "./Sidebar";
import Signout from "./Signout";

const Profile = () => {
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated]);

  const createdAt = new Date(user.createdAt).toLocaleDateString("en-GB");

  return (
    <Fragment>
      <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
        <Sidebar />
        <div className="m-3 w-full text-xl text-white font-semibold">
          <div className="grid grid-cols-2">
            <div className="">
              <h4 className="text-sm font-light">
                <Link to="/">Account</Link> / {user.caseManagerName}
              </h4>
              <h2 className=" text-2xl font-semibold text-white leading-tight">
                Account Details
              </h2>
            </div>
            <div className="flex justify-self-end pr-8 self-center gap-8">
              <Signout />
            </div>
          </div>

          {/* <!-- component --> */}
          <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
            {loading ? (
              <Loader />
            ) : (
              <div className="overflow-x-auto shadow-md overflow-scroll scrollbar-hide rounded-2xl">
                <div className="  mx-auto  text-gray-800">
                  <form className=" w-full text-sm  p-8 mx-auto space-y-6 rounded-2xl shadow bg-white ng-untouched ng-pristine ng-valid">
                    <div className="grid grid-cols-3 gap-8">
                      <div className="col-span-2 text-indigo-600 text-2xl font-bold leading-tight">
                        Your Profile
                      </div>
                      <div className="flex justify-end items-center gap-3">
                        <label className="block text-gray-600 mb-1 ml-1">
                          User Role :
                        </label>
                        <select
                          id="name"
                          type="text"
                          className={`block text-center p-2 focus:outline-none rounded border border-gray-200 bg-white ${
                            user.role === "superAdmin"
                              ? "bg-gradient-to-b from-green-400 to-green-500"
                              : user.role === "admin"
                              ? "bg-gradient-to-b from-blue-300 to-blue-400"
                              : ""
                          }`}
                          value={user.role}
                          disabled
                        >
                          <option value="superAdmin">MAHF Admin</option>
                          <option value="admin">Agency Admin</option>
                        </select>
                      </div>

                      <hr className="col-span-full border-dashed border-gray-500" />
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Name :
                        </label>
                        <input
                          id="carName"
                          type="text"
                          className="block w-full p-2 rounded focus:outline-none border border-gray-200 bg-white"
                          value={user.caseManagerName}
                          readOnly
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Email :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 rounded focus:outline-none border border-gray-200 bg-white"
                          value={user.email}
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Phone No. :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={user.phone}
                          readOnly
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Agency :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={user.agency}
                          readOnly
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          UIN :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={user._id}
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Date :
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded autoexpand border border-gray-200 bg-white"
                          value={createdAt}
                          readOnly
                        />
                      </div>

                      <div className="col-span-full">
                        <label className="block text-gray-600 mb-1 ml-1">
                          Address :
                        </label>
                        <textarea
                          rows="2"
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={user.address}
                          readOnly
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          State :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={user.state}
                          readOnly
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          City :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={user.city}
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Zip code :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-200 bg-white"
                          value={user.zipcode}
                          readOnly
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Profile;
