export const ALL_PICKUP_REQUEST="ALL_PICKUP_REQUEST";
export const ALL_PICKUP_SUCCESS="ALL_PICKUP_SUCCESS";
export const ALL_PICKUP_FAIL="ALL_PICKUP_FAIL";

// export const ADMIN_CAR_REQUEST="ADMIN_CAR_REQUEST";
// export const ADMIN_CAR_SUCCESS="ADMIN_CAR_SUCCESS";
// export const ADMIN_CAR_FAIL="ADMIN_CAR_FAIL";

export const NEW_PICKUP_REQUEST="NEW_PICKUP_REQUEST";
export const NEW_PICKUP_SUCCESS="NEW_PICKUP_SUCCESS";
export const NEW_PICKUP_RESET="NEW_PICKUP_RESET";
export const NEW_PICKUP_FAIL="NEW_PICKUP_FAIL";


export const UPDATE_PICKUP_STATUS_REQUEST="UPDATE_PICKUP_STATUS_REQUEST";
export const UPDATE_PICKUP_STATUS_SUCCESS="UPDATE_PICKUP_STATUS_SUCCESS";
export const UPDATE_PICKUP_STATUS_RESET="UPDATE_PICKUP_STATUS_RESET";
export const UPDATE_PICKUP_STATUS_FAIL="UPDATE_PICKUP_STATUS_FAIL";

export const DELETE_PICKUP_REQUEST="DELETE_PICKUP_REQUEST";
export const DELETE_PICKUP_SUCCESS="DELETE_PICKUP_SUCCESS";
export const DELETE_PICKUP_RESET="DELETE_PICKUP_RESET";
export const DELETE_PICKUP_FAIL="DELETE_PICKUP_FAIL";

export const PICKUP_DETAILS_REQUEST="PICKUP_DETAILS_REQUEST";
export const PICKUP_DETAILS_SUCCESS="PICKUP_DETAILS_SUCCESS";
export const PICKUP_DETAILS_FAIL="PICKUP_DETAILS_FAIL";

export const CLEAR_ERRORS="CLEAR_ERRORS";