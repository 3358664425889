import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Signout from "./Signout";
import { BsCurrencyDollar } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { clearErrors, createApplication } from "../actions/applicationAction";
import { NEW_APPLICATION_RESET } from "../constants/applicationConstants";
import Loader from "./Loader";
import { Player } from "@lottiefiles/react-lottie-player";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const NewApplication = () => {
  const { loading, error, success } = useSelector(
    (state) => state.newApplication
  );

  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [floorNo, setFloorNo] = useState("");
  const [clientState, setClientState] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientZipcode, setClientZipcode] = useState("");
  const [stairs, setStairs] = useState("");
  const [elevator, setElevator] = useState("");
  const [totalLive, setTotalLive] = useState(0);
  const [namesAges, setNamesAges] = useState("");
  const [incomeWork, setIncomeWork] = useState();
  const [incomeVetBenefits, setIncomeVetBenefits] = useState();
  const [incomeTANF, setIncomeTANF] = useState();
  const [incomeFoodStamps, setIncomeFoodStamps] = useState();
  const [incomeSsiSsd, setIncomeSsiSsd] = useState();
  const [incomeChildSupport, setIncomeChildSupport] = useState();
  const [otherIncome, setOtherIncome] = useState();
  const [rentMortgage, setRentMortgage] = useState();
  const [gasOil, setGasOil] = useState();
  const [electricity, setElectricity] = useState();
  const [cable, setCable] = useState();
  const [phoneBill, setPhoneBill] = useState();
  const [food, setFood] = useState();
  const [otherPayments, setOtherPayments] = useState();

  const [reasonReferral, setReasonReferral] = useState("");
  const [bed1, setBed1] = useState("");
  const [bed2, setBed2] = useState("");
  const [bed3, setBed3] = useState("");
  const [bed4, setBed4] = useState("");
  const [bed5, setBed5] = useState("");
  const [bed1Mattress, setBed1Mattress] = useState("No");
  const [bed2Mattress, setBed2Mattress] = useState("No");
  const [bed3Mattress, setBed3Mattress] = useState("No");
  const [bed4Mattress, setBed4Mattress] = useState("No");
  const [bed5Mattress, setBed5Mattress] = useState("No");
  const [bed1Spring, setBed1Spring] = useState("No");
  const [bed2Spring, setBed2Spring] = useState("No");
  const [bed3Spring, setBed3Spring] = useState("No");
  const [bed4Spring, setBed4Spring] = useState("No");
  const [bed5Spring, setBed5Spring] = useState("No");
  const [bed1Frame, setBed1Frame] = useState("No");
  const [bed2Frame, setBed2Frame] = useState("No");
  const [bed3Frame, setBed3Frame] = useState("No");
  const [bed4Frame, setBed4Frame] = useState("No");
  const [bed5Frame, setBed5Frame] = useState("No");
  const [bedLinens, setBedLinens] = useState("No");
  const [dresser, setDresser] = useState("No");
  const [nightStand, setNightStand] = useState("No");
  const [sofeLoveseat, setSofeLoveseat] = useState("");
  const [armChair, setArmChair] = useState("No");
  const [tv, setTv] = useState("No");
  const [tvStand, setTvStand] = useState("No");
  const [endTable, setEndTable] = useState("No");
  const [floorLamp, setFloorLamp] = useState("No");
  const [tableLamp, setTableLamp] = useState("No");
  const [bookshelf, setBookshelf] = useState("No");
  const [desk, setDesk] = useState("No");
  const [kitchenTable, setKitchenTable] = useState("");
  const [kitchenChairs, setKitchenChairs] = useState("No");
  const [dishes, setDishes] = useState("No");
  const [silverware, setSilverware] = useState("No");
  const [potsPans, setPotsPans] = useState("No");
  const [notListed, setNotListed] = useState("");
  const [reasonForReferral, setReasonForReferral] = useState("");
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState("");
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [totalClientContribution, setTotalClientContribution] = useState(0);
  const [totalAgencyContribution, setTotalAgencyContribution] = useState(0);
  const [tAndC, setTAndC] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  let navigate = useNavigate();

  const handleCheckboxChange = () => {
    setTAndC(!tAndC);
    setIsModalOpen(false);
  };

  const totalIncome =
    parseInt(incomeWork) +
    parseInt(incomeVetBenefits) +
    parseInt(incomeTANF) +
    parseInt(incomeFoodStamps) +
    parseInt(incomeSsiSsd) +
    parseInt(incomeChildSupport) +
    parseInt(otherIncome);

  const totalMonthlyPayments =
    parseInt(rentMortgage) +
    parseInt(gasOil) +
    parseInt(electricity) +
    parseInt(cable) +
    parseInt(phoneBill) +
    parseInt(food) +
    parseInt(otherPayments);

  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      setShowAlert(true);
      dispatch({ type: NEW_APPLICATION_RESET });
    }
  }, [dispatch, alert, error, success]);

  const createApplicationSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("clientName", clientName);
    myForm.set("email", email);
    myForm.set("phone", phone);
    myForm.set("maritalStatus", maritalStatus);
    myForm.set("ethnicity", ethnicity);
    myForm.set("clientAddress", clientAddress);
    myForm.set("floorNo", floorNo);
    myForm.set("clientState", clientState);
    myForm.set("clientCity", clientCity);
    myForm.set("clientZipcode", clientZipcode);
    myForm.set("stairs", stairs);
    myForm.set("elevator", elevator);
    myForm.set("totalLive", totalLive);
    myForm.set("namesAges", namesAges);
    myForm.set("incomeWork", incomeWork);
    myForm.set("incomeVetBenefits", incomeVetBenefits);
    myForm.set("incomeTANF", incomeTANF);
    myForm.set("incomeFoodStamps", incomeFoodStamps);
    myForm.set("incomeSsiSsd", incomeSsiSsd);
    myForm.set("incomeChildSupport", incomeChildSupport);
    myForm.set("otherIncome", otherIncome);
    myForm.set("totalIncome", totalIncome);
    myForm.set("rentMortgage", rentMortgage);
    myForm.set("gasOil", gasOil);
    myForm.set("electricity", electricity);
    myForm.set("cable", cable);
    myForm.set("phoneBill", phoneBill);
    myForm.set("food", food);
    myForm.set("otherPayments", otherPayments);
    myForm.set("totalMonthlyPayments", totalMonthlyPayments);
    myForm.set("reasonReferral", reasonReferral);
    myForm.set("bed1", bed1);
    myForm.set("bed2", bed2);
    myForm.set("bed3", bed3);
    myForm.set("bed4", bed4);
    myForm.set("bed5", bed5);
    myForm.set("bed1Mattress", bed1Mattress);
    myForm.set("bed2Mattress", bed2Mattress);
    myForm.set("bed3Mattress", bed3Mattress);
    myForm.set("bed4Mattress", bed4Mattress);
    myForm.set("bed5Mattress", bed5Mattress);
    myForm.set("bed1Spring", bed1Spring);
    myForm.set("bed2Spring", bed2Spring);
    myForm.set("bed3Spring", bed3Spring);
    myForm.set("bed4Spring", bed4Spring);
    myForm.set("bed5Spring", bed5Spring);
    myForm.set("bed1Frame", bed1Frame);
    myForm.set("bed2Frame", bed2Frame);
    myForm.set("bed3Frame", bed3Frame);
    myForm.set("bed4Frame", bed4Frame);
    myForm.set("bed5Frame", bed5Frame);
    myForm.set("bedLinens", bedLinens);
    myForm.set("dresser", dresser);
    myForm.set("nightStand", nightStand);
    myForm.set("sofeLoveseat", sofeLoveseat);
    myForm.set("armChair", armChair);
    myForm.set("tv", tv);
    myForm.set("tvStand", tvStand);
    myForm.set("endTable", endTable);
    myForm.set("floorLamp", floorLamp);
    myForm.set("tableLamp", tableLamp);
    myForm.set("bookshelf", bookshelf);
    myForm.set("desk", desk);
    myForm.set("kitchenTable", kitchenTable);
    myForm.set("kitchenChairs", kitchenChairs);
    myForm.set("dishes", dishes);
    myForm.set("silverware", silverware);
    myForm.set("potsPans", potsPans);
    myForm.set("notListed", notListed);
    myForm.set("reasonForReferral", reasonForReferral);
    myForm.set("howDidYouHearAboutUs", howDidYouHearAboutUs);
    myForm.set("deliveryInstructions", deliveryInstructions);
    myForm.set("totalClientContribution", totalClientContribution);
    myForm.set("totalAgencyContribution", totalAgencyContribution);
    myForm.set("tAndC", tAndC);

    dispatch(createApplication(myForm));
  };

  return (
    <Fragment>
      <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
        <Sidebar />
        <div className="m-3 w-full text-xl text-white font-semibold">
          <div className="grid grid-cols-2">
            <div className="">
              <h4 className="text-sm font-light">
                <Link to="/">Applications</Link> / Create Application
              </h4>
              <h2 className=" text-2xl font-semibold text-white leading-tight">
                New Request Application
              </h2>
            </div>
            <div className="flex justify-self-end pr-8 self-center gap-8">
              <Signout />
            </div>
          </div>

          {/* <!-- component --> */}
          <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
            {loading ? (
              <Loader />
            ) : (
              <div className="overflow-x-auto shadow-md  overflow-scroll scrollbar-hide rounded-2xl">
                <div className="  mx-auto  text-gray-800">
                  <form
                    className=" w-full text-sm  p-8 mx-auto space-y-6 rounded-2xl shadow bg-white scrollbar-hide ng-untouched ng-pristine ng-valid"
                    onSubmit={createApplicationSubmitHandler}
                  >
                    <div className="grid grid-cols-3 gap-8">
                      <div className="col-span-full text-end">
                        All fields marked * are required.
                      </div>
                      <hr className="col-span-full border-dashed border-gray-500" />
                      <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                        Client Information
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Client Name* :
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 rounded focus:outline-none border border-gray-300 bg-white"
                          value={clientName}
                          onChange={(e) => setClientName(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Email* :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 rounded focus:outline-none border border-gray-300 bg-white"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Phone No.* :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300  bg-white"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Marital Status* :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={maritalStatus}
                          onChange={(e) => setMaritalStatus(e.target.value)}
                          required
                        >
                          <option value="">Please Select One</option>
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                          <option value="Separated">Separated</option>
                          <option value="Divorced">Divorced</option>
                          <option value="Widowed">Widowed</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Race/Ethnicity* :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={ethnicity}
                          onChange={(e) => setEthnicity(e.target.value)}
                          required
                        >
                          <option value="">Please Select One</option>
                          <option value="Caucasian">Caucasian</option>
                          <option value="African American">
                            African American
                          </option>
                          <option value="Hispanic">Hispanic</option>
                          <option value="Asian">Asian</option>
                          <option value="European">European</option>
                          <option value="Caribbean">Caribbean</option>
                          <option value="WidMiddle Easternowed">
                            Middle Eastern
                          </option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div></div>
                      <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                        Client Location
                      </div>

                      <div className="col-span-full">
                        <label className="block text-gray-600 mb-1 ml-1">
                          Address* :
                        </label>
                        <textarea
                          rows="2"
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={clientAddress}
                          onChange={(e) => setClientAddress(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Floor No.* :{" "}
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={floorNo}
                          onChange={(e) => setFloorNo(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          State* :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={clientState}
                          onChange={(e) => setClientState(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          City* :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={clientCity}
                          onChange={(e) => setClientCity(e.target.value)}
                          required
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Zip code* :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={clientZipcode}
                          onChange={(e) => setClientZipcode(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Stairs* :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded autoexpand border border-gray-300 bg-white"
                          onChange={(e) => setStairs(e.target.value)}
                          required
                        >
                          <option value="">Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Elevator* :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded autoexpand border border-gray-300 bg-white"
                          onChange={(e) => setElevator(e.target.value)}
                          required
                        >
                          <option value="">Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div className="col-span-full">
                        <label className="block text-gray-600 mb-1 ml-1">
                          Total no. of live at client location* :
                        </label>
                        <input
                          id="name"
                          type="number"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={totalLive}
                          onChange={(e) => setTotalLive(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-span-full">
                        <label className="block text-gray-600  mb-1 ml-1">
                          All lives names & ages* :
                        </label>
                        <textarea
                          rows="2"
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={namesAges}
                          onChange={(e) => setNamesAges(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                        Financial Household Monthly Income
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Income from work* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={incomeWork}
                            onChange={(e) => setIncomeWork(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Income from vet benefits* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={incomeVetBenefits}
                            onChange={(e) =>
                              setIncomeVetBenefits(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Income from TANF* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={incomeTANF}
                            onChange={(e) => setIncomeTANF(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Income from food stamps* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={incomeFoodStamps}
                            onChange={(e) =>
                              setIncomeFoodStamps(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Income from SSI/SSD* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={incomeSsiSsd}
                            onChange={(e) => setIncomeSsiSsd(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Income from child support* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={incomeChildSupport}
                            onChange={(e) =>
                              setIncomeChildSupport(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Other Income* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={otherIncome}
                            onChange={(e) => setOtherIncome(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Total Income* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={totalIncome}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className=""></div>

                      <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                        Household Monthly Payments
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Rent/Mortgage Payment* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={rentMortgage}
                            onChange={(e) => setRentMortgage(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Gas/Oil Bill* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={gasOil}
                            onChange={(e) => setGasOil(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Electricity Bill* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={electricity}
                            onChange={(e) => setElectricity(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Cable Bill* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={cable}
                            onChange={(e) => setCable(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Phone Bill* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={phoneBill}
                            onChange={(e) => setPhoneBill(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Food Expenses* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={food}
                            onChange={(e) => setFood(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Other Payments* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={otherPayments}
                            onChange={(e) => setOtherPayments(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Total Monthly Payments* :
                        </label>
                        <div className="relative">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <button className="p-1 focus:outline-none focus:ring">
                              <BsCurrencyDollar className="text-green-700 text-base" />
                            </button>
                          </span>
                          <input
                            id="name"
                            type="number"
                            className="block w-full pl-10 p-2 focus:outline-none rounded border border-gray-300 bg-white"
                            value={totalMonthlyPayments}
                            readOnly
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Reasons for referral :{" "}
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={reasonReferral}
                          onChange={(e) => setReasonReferral(e.target.value)}
                        />
                      </div>

                      <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                        Furnitures Requested
                      </div>
                      <div className="col-span-full text-indigo-500 text-lg">
                        Bedroom
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 1 :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed1}
                          onChange={(e) => setBed1(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Twin">Twin</option>
                          <option value="Full">Full</option>
                          <option value="Queen">Queen</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 2 :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed2}
                          onChange={(e) => setBed2(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Twin">Twin</option>
                          <option value="Full">Full</option>
                          <option value="Queen">Queen</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 3 :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed3}
                          onChange={(e) => setBed3(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Twin">Twin</option>
                          <option value="Full">Full</option>
                          <option value="Queen">Queen</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 1 Mattress :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed1Mattress}
                          onChange={(e) => setBed1Mattress(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 2 Mattress :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed2Mattress}
                          onChange={(e) => setBed2Mattress(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 3 Mattress :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed3Mattress}
                          onChange={(e) => setBed3Mattress(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 1 Box Spring :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed1Spring}
                          onChange={(e) => setBed1Spring(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 2 Box Spring :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed2Spring}
                          onChange={(e) => setBed2Spring(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 3 Box Spring :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed3Spring}
                          onChange={(e) => setBed3Spring(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 1 Frame :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed1Frame}
                          onChange={(e) => setBed1Frame(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 2 Frame :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed2Frame}
                          onChange={(e) => setBed2Frame(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 3 Frame :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed3Frame}
                          onChange={(e) => setBed3Frame(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 4 :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed4}
                          onChange={(e) => setBed4(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Twin">Twin</option>
                          <option value="Full">Full</option>
                          <option value="Queen">Queen</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 5 :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed5}
                          onChange={(e) => setBed5(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Twin">Twin</option>
                          <option value="Full">Full</option>
                          <option value="Queen">Queen</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed Linens :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bedLinens}
                          onChange={(e) => setBedLinens(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 4 Mattress :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed4Mattress}
                          onChange={(e) => setBed4Mattress(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 5 Mattress :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed5Mattress}
                          onChange={(e) => setBed5Mattress(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Dresser :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={dresser}
                          onChange={(e) => setDresser(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 4 Box Spring :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed4Spring}
                          onChange={(e) => setBed4Spring(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 5 Box Spring :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed5Spring}
                          onChange={(e) => setBed5Spring(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Nightstand :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={nightStand}
                          onChange={(e) => setNightStand(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 4 Frame :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed4Frame}
                          onChange={(e) => setBed4Frame(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Bed 5 Frame :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bed5Frame}
                          onChange={(e) => setBed5Frame(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>

                      <div className="col-span-full text-indigo-500 text-lg">
                        Living Room
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Sofa or Loveseat :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={sofeLoveseat}
                          onChange={(e) => setSofeLoveseat(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Sofa">Sofa</option>
                          <option value="Loveseat">Loveseat</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Armchair :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={armChair}
                          onChange={(e) => setArmChair(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>

                      <div className="col-span-full text-indigo-500 text-lg">
                        Electronics
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          TV :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={tv}
                          onChange={(e) => setTv(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          TV Stand :
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={tvStand}
                          onChange={(e) => setTvStand(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          End Table :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={endTable}
                          onChange={(e) => setEndTable(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Floor Lamp :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={floorLamp}
                          onChange={(e) => setFloorLamp(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Table Lamp :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={tableLamp}
                          onChange={(e) => setTableLamp(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>

                      <div className="col-span-full text-indigo-500 text-lg">
                        Study
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Book Shelf :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={bookshelf}
                          onChange={(e) => setBookshelf(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Desk :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={desk}
                          onChange={(e) => setDesk(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>

                      <div className="col-span-full text-indigo-500 text-lg">
                        Kitchen
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Kitchen Table :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={kitchenTable}
                          onChange={(e) => setKitchenTable(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Small">Small</option>
                          <option value="Large">Large</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Kitchen Chairs :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={kitchenChairs}
                          onChange={(e) => setKitchenChairs(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Dishes :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={dishes}
                          onChange={(e) => setDishes(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Silverware :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={silverware}
                          onChange={(e) => setSilverware(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Pots & Pans :{" "}
                        </label>
                        <select
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={potsPans}
                          onChange={(e) => setPotsPans(e.target.value)}
                        >
                          <option value="">Please Select One</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div className="col-span-full text-indigo-500 text-lg">
                        Other{" "}
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Other not listed :{" "}
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={notListed}
                          onChange={(e) => setNotListed(e.target.value)}
                        />
                      </div>
                      <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                        Reason For Referral
                      </div>
                      <div className="col-span-full">
                        <label className="block text-gray-600 mb-1 ml-1">
                          Be Specific (Eviction, Domestic Violence, Loss Of Job,
                          Fire, Addiction, Veteran, Homelessness, Mental
                          Disability, Incarceration, Etc.) Note If The Situation
                          Is an Emergency Or Not, The Earliest Date for Delivery
                          Can Be Scheduled.*
                        </label>
                        <textarea
                          rows="3"
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={reasonForReferral}
                          onChange={(e) => setReasonForReferral(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-span-full">
                        <label className="block text-gray-600 mb-1 ml-1">
                          How did you hear about us* :{" "}
                        </label>
                        <textarea
                          rows="3"
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={howDidYouHearAboutUs}
                          onChange={(e) =>
                            setHowDidYouHearAboutUs(e.target.value)
                          }
                          required
                        />
                      </div>
                      <div className="col-span-full text-indigo-600 text-2xl font-bold leading-tight">
                        Delivery Instructions
                      </div>
                      <div className="col-span-full">
                        <label className="block text-gray-600 mb-1 ml-1">
                          Describe Any Other Important Information For Drivers :{" "}
                        </label>
                        <textarea
                          rows="3"
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={deliveryInstructions}
                          onChange={(e) =>
                            setDeliveryInstructions(e.target.value)
                          }
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Total Client Contribution* :{" "}
                        </label>
                        <input
                          id="name"
                          type="number"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={totalClientContribution}
                          onChange={(e) =>
                            setTotalClientContribution(e.target.value)
                          }
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Total Agency Contribution* :{" "}
                        </label>
                        <input
                          id="name"
                          type="number"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-300 bg-white"
                          value={totalAgencyContribution}
                          onChange={(e) =>
                            setTotalAgencyContribution(e.target.value)
                          }
                          required
                        />
                      </div>

                      {tAndC === true ? (
                        <button
                          type="submit"
                          className="col-span-full bg-indigo-500 p-4 rounded-md text-white"
                          disabled={tAndC === false ? true : false}
                        >
                          Submit Application
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="col-span-full bg-indigo-500 p-4 rounded-md text-white"
                          onClick={() => setIsModalOpen(true)}
                        >
                          Next
                        </button>
                      )}

                      {isModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="fixed inset-0 bg-gray-800 bg-opacity-90 "></div>
                          <div className="bg-white grid grid-cols-3 w-[70vw] p-10 rounded-lg z-10">
                            <div className=" text-indigo-600 text-2xl font-bold leading-tight">
                              Declaration
                            </div>
                            <div className="text-red-700 col-span-2 font-normal text-end">
                              Please read the TERMS & CONDITIONS below carefully
                              before submitting this application.
                            </div>
                            <div className="col-span-full">
                              {" "}
                              <h1 className="py-4 text-lg">
                                TERMS AND CONDITIONS :
                              </h1>
                              <p className="font-normal text-justify">
                                Make A Home Foundation serves Veterans and
                                families in need by providing household
                                furnishings to qualifying families. Our
                                donations program allows us to give a helping
                                hand to those who are attempting to rebuild
                                their lives. We only work through community
                                agencies and their case managers to make this
                                happen.
                                <br />
                                <br />
                                Most items are USED. These are items that have
                                been donated to us. Nothing is ripped or
                                stained, but some will show usage. Items
                                selected for delivery are subject to
                                availability. They may not be the style or color
                                that the client prefers or be matching sets, but
                                all will be in good working condition and we
                                will make every attempt to provide matching sets
                                where possible.
                                <br />
                                <br />
                                <p className="font-medium">
                                  PLEASE INFORM YOUR CLIENT OF THIS.
                                </p>
                                <br />
                                *Please note the delivery fee for your area. Be
                                very specific when describing the delivery
                                location. Deliveries will be made curbside to
                                locations that are 3rd floor and above with no
                                elevator. Be sure the client will be home for
                                the delivery since re-deliveries will incur an
                                additional delivery fee.
                                <br />
                                <br />
                                ALL PURCHASES ARE FINAL – NO REFUNDS WILL BE
                                GIVEN. Be sure when the application is filled
                                out that items requested will fit the space
                                allotted (i.e. queen bed and dresser in the
                                bedroom, sofa and side chair in the living room,
                                etc.). If space is at a premium, make a note of
                                it on the application. Be sure that furniture
                                will fit through doorways and halls.
                              </p>
                              <br />
                              Please Note:
                              <ul className="mt-2 marker:text-gray-700 list-disc font-normal pl-5 space-y-1">
                                <li>
                                  If no one is there to receive furniture on the
                                  designated delivery day there will be a
                                  redelivery charge.
                                </li>
                                <li>
                                  Please do not request items you already have
                                  or do not need.
                                </li>
                                <li>
                                  Most items are used and donated, if you do not
                                  receive it we probably are out of stock.
                                </li>
                              </ul>
                            </div>

                            <div className="col-span-full mt-8 justify-center gap-2 flex">
                              <input
                                id="name"
                                type="checkbox"
                                className="block w-[1rem] p-2 focus:outline-none rounded border border-gray-300 bg-white"
                                checked={tAndC}
                                onChange={handleCheckboxChange}
                              />
                              <label className="block text-gray-600  ml-1">
                                I agree to the Terms and Conditions{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {showAlert && (
                        <div className="fixed inset-0  flex items-center justify-center z-50">
                          <div className="fixed inset-0 bg-gray-800 bg-opacity-70 "></div>
                          <div className="bg-white p-6 w-2/4 rounded-lg z-10">
                            <Player
                              autoplay
                              loop
                              src="https://assets10.lottiefiles.com/packages/lf20_a7kivydz.json"
                              keepLastFrame
                              style={{ height: "200px", width: "200px" }}
                            ></Player>
                            <h2 className="text-xl font-semibold mb-4 text-center ">
                              Application submitted succesfully
                            </h2>
                            <div className="flex justify-center">
                              <button
                                className="px-4 py-2 rounded-lg flex gap-2 justify-between items-center bg-gradient-to-b from-gray-500 to-gray-700 text-white mr-2"
                                onClick={() => {
                                  navigate("/all-application");
                                }}
                              >
                                View Submitted Applications{" "}
                                <FiChevronRight className="text-xl" />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default NewApplication;
