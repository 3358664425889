import { Routes, Route } from "react-router-dom";
import React, { Fragment } from 'react'
import ProtectedRoute from '../components/ProtectedRoute';
import Login from "../components/Login";
import Application from '../components/Application';
import AllApplications from '../components/AllApplications';
import AllPickups from '../components/AllPickups';
import NewApplication from '../components/NewApplication';
import AllUsers from '../components/AllUsers';
import User from '../components/User';
import AddUser from '../components/AddUser';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from "../components/ResetPassword";
import Profile from '../components/Profile';
import Dashboard from '../components/Dashboard';
import Error from '../components/Error';
import Developer from '../components/Developer';
import Pickup from '../components/Pickup';
import NewPickup from '../components/NewPickup';


const Routers = () => {
  return (
<Fragment>
<Routes>
<Route path="/login" element={<Login />} />
<Route exact path="/password/forgot" element={<ForgotPassword/>} />
<Route exact path="/password/reset/:token" element={<ResetPassword/>} />
<Route exact path="*" element={<Error/>} />
<Route exact path="/developer" element={<Developer/>} />
<Route exact path="/pickup-request" element={<NewPickup/>} />

<Route path="/" element={<ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>} />

<Route path="/new-application" element={<ProtectedRoute>
            <NewApplication />
          </ProtectedRoute>} />

<Route path="/all-application/:keyword" element={<ProtectedRoute>
            <AllApplications />
          </ProtectedRoute>} />    
          <Route path="/all-application" element={<ProtectedRoute>
            <AllApplications />
          </ProtectedRoute>} />        
<Route path="/application/:id" element={<ProtectedRoute>
                      <Application />
          </ProtectedRoute>} />


          <Route path="/all-users" element={<ProtectedRoute>
            <AllUsers />
          </ProtectedRoute>} /> 
          <Route path="/user/:id" element={<ProtectedRoute>
                      <User />
          </ProtectedRoute>} />
          <Route path="/user/register" element={<ProtectedRoute>
                      <AddUser />
          </ProtectedRoute>} />
          <Route path="/account" element={<ProtectedRoute>
                      <Profile />
          </ProtectedRoute>} />

          <Route path="/all-pickup-requests" element={<ProtectedRoute>
            <AllPickups />
          </ProtectedRoute>} />    
          <Route path="/pickup/:id" element={<ProtectedRoute>
            <Pickup />
          </ProtectedRoute>} />     
          
          
{/* <Route path="/dashboard" element={<ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>} /> */}
          </Routes>
</Fragment>

  )
}

export default Routers