import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Signout from "./Signout";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, register } from "../actions/userAction";
import { REGISTER_USER_RESET } from "../constants/userConstants";
import Loader from "./Loader";
import { useAlert } from "react-alert";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const navigate = useNavigate();
  const { loading, error, success } = useSelector(
    (state) => state.newUser
  );

  const dispatch = useDispatch();
  const alert = useAlert();

  const [role, setRole] = useState("admin");
  const [caseManagerName, setCaseManagerName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [agency, setAgency] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [password, setPassword] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("User registed succesfully");
      navigate("/all-users");
      dispatch({ type: REGISTER_USER_RESET });
    }
  }, [dispatch,navigate, alert, error, success]);

  const registerUserProfile = (e) => {
    e.preventDefault();

    const myForm = new FormData();
    myForm.set("agency", agency);
    myForm.set("role", role);
    myForm.set("email", email);
    myForm.set("phone", phone);
    myForm.set("caseManagerName", caseManagerName);
    myForm.set("address", address);
    myForm.set("state", state);
    myForm.set("city", city);
    myForm.set("zipcode", zipcode);
    myForm.set("password", password);

    console.log(myForm);
    dispatch(register(myForm));
    setIsModalOpen(false);
  };


  console.log(role);



  return (
    <Fragment>
      <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
        <Sidebar />
        <div className="m-3 w-full text-xl text-white font-semibold">
          <div className="grid grid-cols-2">
            <div className="">
              <h4 className="text-sm font-light">Add Users / Register</h4>
              <h2 className=" text-2xl font-semibold text-white leading-tight">
                Register New User
              </h2>
            </div>
            <div className="flex justify-self-end pr-8 self-center gap-8">
              <Signout />
            </div>
          </div>

          {/* <!-- component --> */}
          <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
            {loading ? (
              <Loader />
            ) : (
              <div className="overflow-x-auto shadow-md overflow-scroll scrollbar-hide rounded-2xl">
                <div className="  mx-auto  text-gray-800">
                  <form
                    className=" w-full text-sm  p-8 mx-auto space-y-6 rounded-2xl shadow bg-white ng-untouched ng-pristine ng-valid"
                    onSubmit={registerUserProfile}
                  >
                    <div className="grid grid-cols-3 gap-8">
                      <div className="col-span-2 text-indigo-600 text-2xl font-bold leading-tight">
                        Register User
                      </div>
                      <div className="flex justify-end items-center gap-3">
                        <label className="block text-gray-600 mb-1 ml-1">
                          User Role :
                        </label>
                        <select
                          id="name"
                          className={`block text-center p-2 focus:outline-none rounded border border-gray-400 bg-white ${
                            role === "superAdmin"
                              ? "bg-gradient-to-b from-green-400 to-green-500"
                              : role === "admin"
                              ? "bg-gradient-to-b from-blue-300 to-blue-400"
                              : ""
                          }`}
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          required
                        >
                          <option value="superAdmin">MAHF Admin</option>
                          <option value="admin">Agency Admin</option>
                        </select>

                       
                      </div>

                      <hr className="col-span-full border-dashed border-gray-500" />
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Case Manager Name :
                        </label>
                        <input
                          id="carName"
                          type="text"
                          className="block w-full p-2 rounded focus:outline-none border border-gray-400 bg-white"
                          value={caseManagerName}
                          onChange={(e) => setCaseManagerName(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Email :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 rounded focus:outline-none border border-gray-400 bg-white"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Phone No. :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-400 bg-white"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Agency Name :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-400 bg-white"
                          value={agency}
                          onChange={(e) => setAgency(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-span-full">
                        <label className="block text-gray-600 mb-1 ml-1">
                          Address :
                        </label>
                        <textarea
                          rows="2"
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-400 bg-white"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          State :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-400 bg-white"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          City :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-400 bg-white"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          required
                        />
                      </div>

                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Zip code :
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="block w-full p-2 focus:outline-none rounded border border-gray-400 bg-white"
                          value={zipcode}
                          onChange={(e) => setZipcode(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-gray-600 mb-1 ml-1">
                          Password :
                        </label>
                        <div className="relative">
                          <div className="relative">
                            <span className="absolute inset-y-0 right-0 flex items-center px-2 border-l border-gray-300">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleShowPassword();
                                }}
                                className="p-1 focus:outline-none "
                              >
                                {showPassword ? (
                                  <AiOutlineEyeInvisible className="text-indigo-500 text-base" />
                                ) : (
                                  <AiOutlineEye className="text-indigo-500 text-base" />
                                )}
                              </button>
                            </span>
                            <input
                              type={showPassword ? "text" : "password"}
                              placeholder="Set password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="w-full py-2 pr-10 pl-2 text-sm font-normal  sm:w-full focus:outline-none bg-white text-gray-700
    block p-2  rounded border border-gray-200 "
                            />
                          </div>
                        </div>
                      </div>

                      {isModalOpen && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 "></div>
                          <div className="bg-white p-6 rounded-lg z-10">
                            <h2 className="text-xl font-semibold mb-4">
                              Confirmation
                            </h2>
                            <p className="mb-4">
                              Are you sure you want to register this user?
                            </p>
                            <div className="flex justify-center">
                              <button
                                className="px-4 py-2 rounded-lg bg-red-500 text-white mr-2"
                                onClick={registerUserProfile}
                              >
                                Confirm
                              </button>
                              <button
                                className="px-4 py-2 rounded-lg bg-gray-300 text-gray-800"
                                onClick={() => setIsModalOpen(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      <button
                        type="submit"
                        className="col-span-full bg-indigo-500 p-4 rounded-md text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsModalOpen(true);
                        }}
                      >
                        Register User
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AddUser;
