import {legacy_createStore as createStore,combineReducers,applyMiddleware} from "redux";

import thunk from "redux-thunk";

import {composeWithDevTools} from "redux-devtools-extension";
import { applicationsReducer, applicationReducer,applicationDetailsReducer, newApplicationReducer} from "./reducers/applicationReducer";
import { pickupsReducer,
     pickupReducer,pickupDetailsReducer, newPickupReducer
    } from "./reducers/pickupReducer";
import { userReducer, allUsersReducer, userDetailsReducer, profileReducer, newUserReducer, forgotPasswordReducer,} from "./reducers/userReducer";




const reducer = combineReducers({
    applications: applicationsReducer,
    applicationDetails: applicationDetailsReducer,
    user:userReducer,
    newApplication: newApplicationReducer,
    application: applicationReducer,
    allUsers: allUsersReducer,
    userDetails: userDetailsReducer,
    profile: profileReducer,
    newUser: newUserReducer,
    forgotPassword: forgotPasswordReducer,
    pickups: pickupsReducer,
    pickupDetails: pickupDetailsReducer,
    pickup: pickupReducer,
    newPickup: newPickupReducer,

});




let initialState = {};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
    );


    export default store;