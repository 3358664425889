import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  getAllPickups,
  deletePickup,
} from "../actions/pickupAction";
import Loader from "./Loader";
import Sidebar from "./Sidebar";
import Signout from "./Signout";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { DELETE_PICKUP_RESET } from "../constants/pickupConstants";
import { useAlert } from "react-alert";

const AllPickups = () => {
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.user);

  const { loading, error, pickups } = useSelector(
    (state) => state.pickups
  );

  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.pickup
  );

  const alert = useAlert();
  let navigate = useNavigate();



  const deletePickupHandler = (pickupId) => {
    dispatch(  deletePickup(pickupId));
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { keyword } = useParams();

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      alert.success("Pickup Request Deleted succesfully");
      dispatch({ type: DELETE_PICKUP_RESET });
    }

    dispatch(getAllPickups());
  }, [dispatch, keyword, alert, error,deleteError,isDeleted]);

  const customSort = (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    if (dateA < dateB) return 1;
    else if (dateA > dateB) return -1;
    return 0;
  };

  pickups.sort(customSort);

  const downloadCSV = () => {
    const header = [
      "S. No.",
      "AIN #",
      "Full Name",
      "Date of Request",
      "Email",
      "Phone",
      "Pickup Status",
    ];
    const rows = pickups.map((p, index) => [
      `${index + 1}`,
      p.pickupNo,
      p.name,
      p.createdAt.slice(0, 10),
      p.email,
      p.phone,
      p.pickupStatus,
    ]);
    const csv = [header, ...rows].map((r) => r.join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Pickup Requests.csv";
    link.click();
  };

  return (
    <Fragment>
      <section className="flex gap-6">
        <div className="bg-[#5E72E4] w-full z-[-1] absolute h-48"></div>
        <Sidebar />
        <div className="m-3 w-full text-xl text-white font-semibold">
          <div className="grid grid-cols-2">
            <div className="">
              <h4 className="text-sm font-light">
                Pickup Requests / View All Pickup Requests
              </h4>
              <h2 className=" text-2xl font-semibold text-white leading-tight">
                All Pickup Requests
              </h2>
            </div>
            <div className="flex justify-self-end pr-8 self-center gap-8">
              <button
                onClick={downloadCSV}
                className="bg-white bg-gradient-to-b hover:from-slate-600 hover:to-slate-700 text-indigo-600 font-normal text-sm hover:text-white flex self-center gap-1 py-2 px-6 rounded-md"
              >
                Export CSV
              </button>
              <Signout />
            </div>
          </div>

          {/* <!-- component --> */}
          <div className=" py-2 mx-auto sm:py-4 sm:pr-6 text-gray-800">
            {loading ? (
              <Loader />
            ) : (
              <div className="">
                <div className="overflow-x-auto shadow-md max-h-[85vh] overflow-scroll scrollbar-hide rounded-2xl">
                  <table className="min-w-full text-xs ">
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col className="w-24" />
                    </colgroup>
                    <thead className="bg-white border-b border-opacity-20 sticky top-0 border-gray-900">
                      <tr className="text-left">
                        <th className="p-4">AIN #</th>
                        <th className="p-4">Full Name</th>
                        <th className="p-4">Date of Request</th>
                        <th className="p-4">Email</th>
                        <th className="p-4 text-right">Phone</th>
                        <th className="p-4 text-right w-56">Pickup Status</th>
                        {user.role === "superAdmin" && (
                          <th className="p-4 text-right">Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {pickups.map((pickup) => {
                        // Format createdAt date
                        const createdAt = new Date(
                          pickup.createdAt
                        ).toLocaleDateString("en-GB");
                        return (
                          <tr
                            key={pickup._id}
                            className="border-b border-opacity-20 border-gray-900 bg-white hover:bg-indigo-50 cursor-pointer"
                          >
                            <td className="p-4">
                              <Link to={`/pickup/${pickup._id}`}>
                                <p>{pickup.pickupNo}</p>
                              </Link>
                            </td>
                            <td className="p-4">
                              <Link to={`/pickup/${pickup._id}`}>
                                <p>{pickup.name}</p>
                              </Link>
                            </td>
                            <td className="p-4">
                              <Link to={`/pickup/${pickup._id}`}>
                                <p>{createdAt}</p>
                              </Link>
                            </td>
                            <td className="p-4">
                              <Link to={`/pickup/${pickup._id}`}>
                                <p>{pickup.email}</p>
                              </Link>
                            </td>
                            <td className="p-4 text-right">
                              <Link to={`/pickup/${pickup._id}`}>
                                <p>{pickup.phone}</p>
                              </Link>
                            </td>
                            <td className="p-4 text-right">
                              <Link to={`/pickup/${pickup._id}`}>
                                <span
                                  className={`px-3 py-1 text-white font-semibold rounded-md ${
                                    pickup.pickupStatus ===
                                    "Processing"
                                      ? "bg-gradient-to-b from-orange-300 to-orange-400"
                                      : pickup.pickupStatus ===
                                        "Pickup Scheduled"
                                      ? "bg-gradient-to-b from-violet-400 to-violet-500"
                                      : pickup.pickupStatus ===
                                        "Pickup Completed"
                                      ? "bg-gradient-to-b from-green-300 to-green-400"
                                      : pickup.pickupStatus ===
                                        "Pickup Rejected"
                                      ? "bg-gradient-to-b from-red-300 to-red-400"
                                      : ""
                                  } text-gray-900`}
                                >
                                  <span>{pickup.pickupStatus}</span>
                                </span>
                              </Link>
                            </td>
                            {user.role === "superAdmin" && (
                              <td className="p-4 text-center flex justify-end">
                                <button  
                                onClick={() =>
                                  deletePickupHandler(
                                              pickup._id
                                            )
                                          }
                                          >
                                  <AiOutlineDelete className="text-xl hover:text-red-500" />
                                </button>
                                
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AllPickups;
