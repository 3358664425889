import './App.css';
import Routers from './Routers/Routers';
import store from './store';
import { loadUser } from './actions/userAction';
import { useEffect } from 'react';

function App() {

  //Prevent Inspect
  // window.addEventListener("contextmenu", (e) => e.preventDefault());

  useEffect(() => {

    store.dispatch(loadUser());

  }, []);

  return <Routers />
}

export default App;
