import React from "react";
import { Fragment } from "react";
import help from "./developer_male.png";

const Developer = () => {
  return (
    <Fragment>
      <div className="grid h-screen bg-slate-800 ">
        <div className=" overflow-hidden">
          <main className="grid col-span-4 h-screen  bg-[#071738] overflow-x-hidden overflow-y-auto mb-14">
            <div className="container h-screen grid self-center p-2 mx-auto sm:p-4 dark:text-gray-100">
              <div className="grid grid-cols-2  rounded-xl self-center bg-[#010d23] max-w-xl mx-auto px-10 py-12">
                <div className="col-span-1 mx-auto self-center">
                  <img src={help} alt="" />
                </div>
                <div className="col-span-1 self-center text-right">
                  <h4 className="text-2xl font-light pb-2 leading-normal ">
                    Contact Developer
                  </h4>
                  <p>
                    Developer: <h1 className="text-[#467bed]">Ronit Roy</h1>
                  </p>
                  <p>
                    Email:
                    <a
                      className="text-[#467bed]"
                      href="mailto:truth2it@truth2it.com"
                    >
                      ronitrbusiness@gmail.com
                    </a>
                  </p>
                  <p>
                    Phone:
                    <a className="text-[#467bed]" href="tel:+91 7001726479">
                      +91 70017 26479
                    </a>
                  </p>
                </div>
              </div>

              <h2 className="opacity-5 text-gray-400">©Ronit Roy</h2>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
};

export default Developer;
